import { configureStore } from '@reduxjs/toolkit';

import modalSlice from './modalSlice';
import mobileSearchSlice from './mobileSearchSlice';
import errorModalSlice from './errorModalSlice';
import userLogInSlice from './userLogInSlice';
import couponsAndCategoriesListSlice from './couponsAndCategoriesListSlice';
import filteredCouponsListSlice from './filteredCouponsListSlice';
import userPositionSlice from './userPositionSlice';

const store = configureStore({
  reducer: {
    modal: modalSlice,
    mobileSearch: mobileSearchSlice,
    errorModal: errorModalSlice,
    userLogIn: userLogInSlice,
    couponsAndCategoriesList: couponsAndCategoriesListSlice,
    filteredCouponsList: filteredCouponsListSlice,
    userPosition: userPositionSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
