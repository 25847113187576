import React, { FC, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@pofd-front/storybook';
import QrCode from 'react-qr-code';
import styles from './app-footer.module.scss';
import footerLogoImg from '../../images/icons/logo-footer-pcheck.svg';
import {
  appleStoreLink,
  googlePlayLink,
  supportHref,
} from '../../shared/constants/constatnts';
import appleStoreIcon from '../../images/icons/app-store-icon.svg';
import googlePlayIcon from '../../images/icons/google-play-icon.svg';

const AppFooter: FC = () => {
  const [activeFooterFlag, setActiveFooterFlag] = useState(true);
  const [currentYear] = useState(new Date().getFullYear());
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/redirect_store') {
      setActiveFooterFlag(false);
    } else {
      setActiveFooterFlag(true);
    }
  }, [location.pathname]);

  return (
    <div>
      {activeFooterFlag ? (
        <div className={styles.footer}>
          <Container>
            <div className={styles.footer_top}>
              <div className={styles.footer_desc}>
                <div>
                  <Link data-testid="footer-nav-link-1" to="/">
                    <img src={footerLogoImg} alt="footer_logo" />
                  </Link>
                </div>
                <p>Есть идеи или вопросы? Напишите нам!</p>
                <div>
                  <a
                    data-testid="footer-nav-link-1"
                    href="https://forms.yandex.ru/u/63289a0689d3816378d41c33/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      className={styles.footer_btn}
                      label="Написать"
                      onClick={() => null}
                      product="ofd"
                      size="large"
                      styleType="primary"
                      theme="info"
                      type="button"
                    />
                  </a>
                </div>
              </div>
              <div className={styles.footer_banner}>
                <div className={styles.banner_desc}>
                  <div className={styles.banner_title}>
                    Экономьте удобнее с&nbsp;приложением
                  </div>
                  <div className={styles.banner_text}>
                    Наведите камеру на&nbsp;QR-код, чтобы&nbsp;скачать
                  </div>
                  <div className={styles.links}>
                    <div>
                      <a
                        href={appleStoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={appleStoreIcon} alt="apple store" />
                      </a>
                    </div>
                    <div>
                      <a
                        href={googlePlayLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={googlePlayIcon} alt="google play" />
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.qr_code}>
                    <QrCode
                      value="https://4310416.redirect.appmetrica.yandex.com/podarocheck.ru/?banner_id=&appmetrica_tracking_id=893703387256760737"
                      size={131}
                      viewBox="0 0 231 231"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <p>
                © {currentYear} ООО «Эвотор ОФД» Все права защищены.
                Москва, ул. Усачева, д. 33, стр. 1
              </p>
              <div className={styles.bottom_links}>
                <div>
                  <a
                    data-testid="footer-link-1"
                    target="_blank"
                    href="/policy"
                    className={styles.link}
                  >
                    Политика конфиденциальности
                  </a>
                </div>
                <div>
                  <a
                    data-testid="footer-link-2"
                    className={styles.link}
                    target="_blank"
                    href="/terms"
                  >
                    Пользовательское соглашение
                  </a>
                </div>
                <div>
                  <p>
                    Партнерство:{' '}
                    <a
                      data-testid="footer-link-4"
                      className="link"
                      href={supportHref}
                    >
                      support@podarocheck.ru
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      ) : null}
    </div>
  );
};

export default AppFooter;
