import React, { FC } from 'react';
import styles from './iframe.module.scss';

interface IIframe {
  ssid: string;
  bid: string;
}

const Iframe: FC<IIframe> = ({ ssid, bid }) => (
  <div className={styles.wrap}>
    <iframe
      title="test"
      src={`https://share.flocktory.com/exchange/login?ssid=${ssid}&bid=${bid}&email=xname@flocktory.com`}
      height="100%"
      width="100%"
      frameBorder="0"
    />
  </div>
);

export default Iframe;
