import { createSlice } from '@reduxjs/toolkit';
import IModalSlice from './models/modalSlice.model';

const initialState: IModalSlice = {
  modal: false,
  definition: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,

  reducers: {
    setModalFlag(state, action) {
      return {
        modal: action.payload.modal,
        definition: action.payload.def,
      };
    },
  },
});

export const { setModalFlag } = modalSlice.actions;

export default modalSlice.reducer;
