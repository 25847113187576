import React, { FC } from 'react';

import smartBannerIcon from '../../images/icons/smart-banner-icon.svg';
import closeIcon from '../../images/icons/close.svg';
import { COUPONS_ALL_ID } from '../../utils';

import styles from './smart-banner.module.scss';

interface ISmartBanner {
  onSmartBannerClose: () => void;
}

const SmartBanner: FC<ISmartBanner> = ({ onSmartBannerClose }) => (
  <div className={styles.banner}>
    <div className={styles.main}>
      <div>
        <button
          data-testid="smart_banner-button-1"
          onClick={onSmartBannerClose}
          className={styles['close-btn']}
          type="button"
        >
          <img src={closeIcon} alt="close" />
        </button>
      </div>
      <div className={styles['banner-logo']}>
        <img src={smartBannerIcon} alt="banner icon" />
      </div>
      <div>
        <div className={styles.title}>ПодароЧек: купоны и скидки</div>
        <p className={styles.text}>В приложении еще удобней</p>
      </div>
      <div>
        <a
          data-testid="smart_banner-link-1"
          className="link"
          href={`https://4310416.redirect.appmetrica.yandex.com/podarocheck.ru/?banner_id=${String(
            COUPONS_ALL_ID,
          )}&appmetrica_tracking_id=244432374790679557`}
        >
          Открыть
        </a>
      </div>
    </div>
  </div>
);

export default SmartBanner;
