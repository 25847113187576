import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  addFilteredCouponsList,
  setNextPage,
  setTotalPage,
} from 'store/filteredCouponsListSlice';
import {
  setAccessKey,
  setIsLoading,
} from 'store/couponsAndCategoriesListSlice';
import { GetNewCouponsParams } from '../http/couponsAndCategories/models/search-coupons.model';
import DocumentsHTTPController from '../http/couponsAndCategories/search-coupons-http-controller';

const { getNewCoupons } = DocumentsHTTPController;

const useSearchCoupons = () => {
  const dispatch = useDispatch();

  const searchCoupons = useCallback(
    async (params: GetNewCouponsParams) => {
      dispatch(setIsLoading(true));
      const res = await getNewCoupons(params);
      if (res) {
        dispatch(setAccessKey(res.accessKey));
        // @ts-ignore
        dispatch(setNextPage(res.page.number));
        dispatch(setTotalPage(res.page.totalPages));
      }
      dispatch(setIsLoading(false));
      return res;
    },
    [],
  );

  const addedCoupons = useCallback(
    async (params: GetNewCouponsParams) => {
      dispatch(setIsLoading(true));
      const res = await getNewCoupons(params);
      if (res) {
        // @ts-ignore
        dispatch(addFilteredCouponsList(res.page.content));
        dispatch(setAccessKey(res.accessKey));
      }
      dispatch(setIsLoading(false));
    },
    [],
  );

  return {
    searchCoupons,
    addedCoupons,
  };
};

export default useSearchCoupons;
