import moment from 'moment/moment';
import cryptoJs from 'crypto-js';
import { setErrorModal } from 'store/errorModalSlice';
import { setModalFlag } from 'store/modalSlice';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'store/userLogInSlice';
import { useCallback } from 'react';

const useAuthorization = () => {
  const dispatch = useDispatch();
  const userLoginRequest = useCallback(
    async (
      url: string,
      serverDate: string | null,
      mobilePhone: string,
      // eslint-disable-next-line consistent-return
    ) => {
      if (serverDate !== null) {
        dispatch(setIsLoading(true));
        const dateOrig = new Date(serverDate);
        const date = new Date(
          dateOrig.getTime() +
            dateOrig.getTimezoneOffset() * 60 * 1000,
        );
        const dateTr = moment(date).format('YYYY-MM-DD HH:mm');

        const cryptoToken = cryptoJs
          .MD5(
            cryptoJs
              .SHA512(dateTr)
              .concat(
                cryptoJs.SHA512(mobilePhone.replace(/[^0-9]/g, '')),
              ),
          )
          .toString(cryptoJs.enc.Base64);
        try {
          const response: Response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Login-Token': cryptoToken,
            },
            body: JSON.stringify({
              type: 'PHONE',
              value: mobilePhone.replace(/[^0-9]/g, ''),
            }),
          });
          return response;
        } catch (error) {
          dispatch(
            setErrorModal({
              errorCode: null,
              fullwidth: false,
              itemId: null,
            }),
          );
          dispatch(setModalFlag({ modal: true, def: 'error' }));
        } finally {
          dispatch(setIsLoading(false));
        }
      }
    },
    [dispatch],
  );

  return { userLoginRequest };
};

export default useAuthorization;
