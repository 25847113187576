import { createSlice } from '@reduxjs/toolkit';
import IUserLogInSlice from './models/userLogInSlice.model';

const initialState: IUserLogInSlice = {
  userLogIn: false,
  isLoginLoading: false,
};

const userLogInSlice = createSlice({
  name: 'userLogIn',
  initialState,

  reducers: {
    setUserLogInFlag(state, action) {
      state.userLogIn = action.payload;
    },

    setIsLoading(state, action) {
      state.isLoginLoading = action.payload;
    },
  },
});

export const { setUserLogInFlag, setIsLoading } =
  userLogInSlice.actions;

export default userLogInSlice.reducer;
