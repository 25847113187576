import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from './content-preloader.module.scss';

const cx = classNames.bind(styles);

const CardPagePreloader: FC = memo(() => (
  <div className={cx('placeholder-product-list')}>
    <div className={cx('preload-page-main')}>
      <div className={cx('preload-card-wrapper')}>
        <div className={cx('preload-card-img-wrap')}>
          <div
            className={cx('preload-card-img', 'base-placeholder')}
          />
        </div>
        <div className={cx('preload-card-desc')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div
            className={cx(
              'base-placeholder',
              'paragraph-placeholder',
            )}
          />
          <div className={styles.divider}>
            <div className={styles.left_divider}>
              <div />
            </div>
            <div className={styles.middle_divider} />
            <div className={styles.right_divider}>
              <div />
            </div>
          </div>
          <div
            className={cx('base-placeholder', 'placeholder-title')}
          />
          <div
            className={cx(
              'base-placeholder',
              'paragraph-placeholder',
            )}
          />
        </div>
      </div>
      <div className={cx('preload-desc-wrapper')}>
        <div
          className={cx(
            'base-placeholder',
            'card-paragraph-placeholder',
          )}
        />
        <div
          className={cx(
            'base-placeholder',
            'card-paragraph-placeholder',
          )}
        />
        <div className={cx('card-separator-placeholder')} />
        <div
          className={cx('base-placeholder', 'card-title-placeholder')}
        />
        <div
          className={cx(
            'base-placeholder',
            'card-paragraph-placeholder',
          )}
        />
        <div
          className={cx(
            'base-placeholder',
            'card-paragraph-placeholder',
          )}
        />
      </div>
    </div>
  </div>
));

CardPagePreloader.displayName = 'CardPagePreloader';

export default CardPagePreloader;
