import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useSetPosition from './use-set-position';
import { setPosition } from '../../store/userPositionSlice';

const useGetPosition = () => {
  const { getCurrentPosition, saveNewPosition, getPosition } =
    useSetPosition();
  const dispatch = useDispatch();

  const currentUserPosition = useCallback(() => {
    if (localStorage.getItem('localPosition') !== null) {
      const { regionCode, regionName, cityName } = JSON.parse(
        // @ts-ignore
        localStorage.getItem('localPosition'),
      );

      dispatch(setPosition({ cityName, regionCode, regionName }));

      return { regionCode, regionName, cityName };
    }
    navigator.geolocation.getCurrentPosition(async (position) => {
      if (localStorage.session_token) {
        const { regionCode, regionName, cityName } =
          await getPosition();

        localStorage.setItem(
          'localPosition',
          JSON.stringify({
            regionName,
            cityName,
            regionCode,
          }),
        );

        dispatch(setPosition({ cityName, regionCode, regionName }));

        return { regionCode, regionName, cityName };
      }
      const { regionCode, id, regionName, cityName } =
        await getCurrentPosition(
          Number(position.coords.latitude),
          Number(position.coords.longitude),
        );

      dispatch(setPosition({ cityName, regionCode, regionName }));

      localStorage.setItem(
        'localPosition',
        JSON.stringify({
          regionName,
          cityName,
          regionCode,
        }),
      );

      if (localStorage.session_token) {
        await saveNewPosition(id);
      }
      return { regionCode, id, regionName, cityName };
    });

    return null;
  }, [dispatch, getPosition, saveNewPosition, getCurrentPosition]);

  return { currentUserPosition };
};

export default useGetPosition;
