import React, { FC, memo, useEffect, useState } from 'react';
import QrCode from 'react-qr-code';
import googlePlayIcon from '../../images/icons/google-play-icon.svg';
import appleStoreIcon from '../../images/icons/app-store-icon.svg';
import {
  googlePlayLink,
  appleStoreLink,
} from '../../shared/constants/constatnts';
import styles from './home-page-banner.module.scss';

interface IIconComponent {
  horizontalIconSrc: string;
  verticalIconSrc: string;
}

const IconComponent: FC<IIconComponent> = ({
  horizontalIconSrc,
  verticalIconSrc,
}) => {
  const [iconFlag, setIconFlag] = useState(true);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 992) {
        setIconFlag(true);
      } else {
        setIconFlag(false);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.icon_wrap}>
      <img
        src={iconFlag ? verticalIconSrc : horizontalIconSrc}
        alt="banner icon"
      />
    </div>
  );
};

interface IHomePageBanner {
  title: string;
  subtitle: string;
  theme: string;
  horizontalIconSrc: string;
  verticalIconSrc: string;
  qrLink: string;
}

const HomePageBanner: FC<IHomePageBanner> = memo(
  ({
    title,
    subtitle,
    theme,
    horizontalIconSrc,
    verticalIconSrc,
    qrLink,
  }) => (
    <div className={`${styles.banner} ${styles[theme]}`}>
      <div className={styles.banner_wrap}>
        <div className={styles.info}>
          <div className={`${styles.title} ${styles[theme]}`}>
            {title}
          </div>
          <div className={`${styles.subtitle} ${styles[theme]}`}>
            {subtitle}
          </div>
        </div>
        <div className={styles.icon}>
          <IconComponent
            horizontalIconSrc={horizontalIconSrc}
            verticalIconSrc={verticalIconSrc}
          />
        </div>
        <div className={styles.links_block}>
          <div className={styles.qr_code}>
            <QrCode value={qrLink} size={141} viewBox="0 0 231 231" />
          </div>
          <div>
            <div className={`${styles.desc} ${styles[theme]}`}>
              Наведите камеру на&nbsp;QR-код, чтобы&nbsp;скачать
            </div>
            <div className={styles.links}>
              <div>
                <a href={appleStoreLink}>
                  <img src={appleStoreIcon} alt="apple store" />
                </a>
              </div>
              <div>
                <a href={googlePlayLink}>
                  <img src={googlePlayIcon} alt="google play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ),
);

HomePageBanner.displayName = 'HomePageBanner';

export default HomePageBanner;
