import React, { FC, memo, useCallback, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ICoupons } from '../../interfaces';
import {
  groupCouponsList,
  setAccessKey,
  setCouponsList,
  setShopCategories,
} from '../../store/couponsAndCategoriesListSlice';
import { RootState } from '../../store';
import useGetPosition from '../../shared/hooks/use-get-position';
import useCoupons from '../../shared/hooks/use-coupons';

const Wrapper: FC = memo(() => {
  const { couponList } = useSelector(
    (state: RootState) => state.couponsAndCategoriesList,
  );
  const { currentUserPosition } = useGetPosition();
  const { getCouponsAndCategories } = useCoupons();
  const { posObjData } = useSelector(
    (state: RootState) => state.userPosition,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await currentUserPosition();
    })();
  }, [currentUserPosition]);

  const handleGetCoupons = useCallback(async () => {
    const { coupons, shopCategories, accessKey } =
      await getCouponsAndCategories(
        posObjData.cityName,
        posObjData.regionCode,
        posObjData.regionName,
      );
    dispatch(setCouponsList(coupons));
    dispatch(setShopCategories(shopCategories));
    dispatch(setAccessKey(accessKey));
  }, [dispatch, getCouponsAndCategories, posObjData]);

  useEffect(() => {
    (async () => {
      await handleGetCoupons();
    })();
  }, [posObjData]);

  const deleteDouble = useCallback(
    (arr: ICoupons[], id: number) =>
      arr.filter((item) => item.couponId !== id),
    [],
  );

  const setNewArr = useCallback(
    (id: number, array: ICoupons[]) =>
      array.filter((item) => item.shop.id === id),
    [],
  );

  const setNewCouponsList = useCallback((couponsList: ICoupons[]) => {
    let workArr = [...couponsList];
    const newArr: ICoupons[] = [];
    workArr.map((coupon) => {
      workArr = deleteDouble(workArr, coupon.couponId);
      // eslint-disable-next-line no-param-reassign
      const newCoupon = {
        ...coupon,
        couponSameStore: setNewArr(coupon.shop.id, workArr),
      };
      newArr.push(newCoupon);
      return null;
    });
    return newArr;
  }, []);

  useEffect(() => {
    if (couponList) {
      const uniqueCoupon: any = {};
      const uniqueCouponsList = setNewCouponsList(couponList).filter(
        // eslint-disable-next-line no-return-assign
        (coupon) =>
          !uniqueCoupon[coupon.shop.id] &&
          (uniqueCoupon[coupon.shop.id] = 1),
      );
      dispatch(groupCouponsList(uniqueCouponsList));
    }
  }, [couponList]);

  return <Outlet />;
});

Wrapper.displayName = 'Wrapper';

export default Wrapper;
