import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from '@pofd-front/storybook';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setUserLogInFlag } from 'store/userLogInSlice';
import { setModalFlag } from 'store/modalSlice';

import {
  LOGIN_CONFIRM_URL,
  LOGIN_URL,
  REG_CONFIRM_URL,
  REG_URL,
} from 'urls';

import { inputMask } from 'utils';

import { setErrorModal } from 'store/errorModalSlice';
import { RootState } from 'store';
import styles from './mobile-authorization.module.scss';
import { supportHref } from '../../shared/constants/constatnts';

const DEFAULT_TIMER_COUNTDOWN = 59;
const DEFAULT_INPUT_COUNTER = 19;
const DEFAULT_DELAY_TIME = 2000;
let counterButtonClicks = 1;

interface IMobileAuthorizationInput {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mobilePhone: string;
}

const MobileAuthorizationInput: FC<IMobileAuthorizationInput> = ({
  onChange,
  onFocus,
  mobilePhone,
}) => (
  <label htmlFor="authEmailInput" className={styles.label}>
    Номер телефона
    <input
      data-testid="mobile_authorization-input-1"
      type="text"
      name="phone"
      className={styles.input}
      id="authMobileInput"
      maxLength={18}
      onChange={onChange}
      onFocus={onFocus}
      value={mobilePhone}
    />
  </label>
);

interface IMobileAuthenticationInput {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MobileAuthenticationInput: FC<IMobileAuthenticationInput> = ({
  onChange,
}) => (
  <label htmlFor="authEmailInput" className={styles.label}>
    Код из SMS
    <input
      data-testid="mobile_authorization-input-2"
      type="text"
      className={styles.input}
      id="authMobileInput"
      onChange={onChange}
      maxLength={4}
      autoComplete="off"
    />
  </label>
);

interface IMobileAuthorization {
  onChangeMainMobileAuthCase: (val: string) => void;
  onUserLogin: (val: string) => void;
  onShipmentFlagToggle: (val: boolean) => void;
  onMobilePhoneChange: (val: string) => void;
  userSignUp: boolean;
  isAuthentication: boolean;
  startCounterFlag: boolean;
  mobilePhone: string;
  shipmentCounter: number;
  shipmentFlag: boolean;
}

const MobileAuthorization: FC<IMobileAuthorization> = ({
  userSignUp,
  onChangeMainMobileAuthCase,
  mobilePhone,
  onMobilePhoneChange,
  isAuthentication,
  startCounterFlag,
  onUserLogin,
  shipmentCounter,
  shipmentFlag,
  onShipmentFlagToggle,
}) => {
  const [loginErrorText, setLoginErrorText] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [counter, setCounter] = useState(0);
  const dispatch = useDispatch();
  const { isLoginLoading } = useSelector(
    (state: RootState) => state.userLogIn,
  );

  const [inputCounter, setInputCounter] = useState(
    DEFAULT_INPUT_COUNTER,
  );

  const clearErrors = () => {
    setLoginError(false);
    setLoginErrorText('');
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    startCounterFlag ? setCounter(DEFAULT_TIMER_COUNTDOWN) : 0;
  }, [startCounterFlag]);

  const counterBtnValue =
    counter >= 10 ? `0:${counter}` : `0:0${counter}`;

  const authButtonText = userSignUp ? 'Зарегистрироваться' : 'Войти';

  const validateMobileTelephone = () => {
    const validTelephone =
      // eslint-disable-next-line no-useless-escape
      /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    const isValid = validTelephone.test(mobilePhone);

    if (!isValid) {
      setLoginErrorText('Проверьте номер телефона');
      setLoginError(true);
    }
    if (!mobilePhone.length) {
      setLoginErrorText('Введите номер телефона');
      setLoginError(true);
    }
    return isValid;
  };

  const handleMobilePhoneChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onMobilePhoneChange(inputMask(e));
  };

  const handleInputFocus = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.value === '' || e.target.value === '+7 ') {
      e.target.value = '+7 ';
    }

    return e.target.value;
  };

  const handleMobileAuthorization = (
    e: React.ChangeEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    clearErrors();
    if (validateMobileTelephone()) {
      if (userSignUp) {
        onUserLogin(REG_URL);
      } else {
        onUserLogin(LOGIN_URL);
      }
    }
  };

  const handleMobileCodeRetry = (
    e: React.ChangeEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (counterButtonClicks === 3 && shipmentCounter > 1) {
      onChangeMainMobileAuthCase('sending not coming');
      counterButtonClicks = 1;
    } else {
      setCounter(DEFAULT_TIMER_COUNTDOWN);
      counterButtonClicks += 1;
      if (userSignUp) {
        onUserLogin(REG_URL);
      } else {
        onUserLogin(LOGIN_URL);
      }

      if (shipmentCounter === 1) {
        onShipmentFlagToggle(false);
      }
    }
  };

  const handleCodeConfirmation = useCallback(
    async (url: string, code: string) => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: localStorage.getItem('session_id'),
            code,
          }),
        });
        dispatch(
          setErrorModal({
            errorCode: response.status,
            fullwidth: false,
            itemId: null,
          }),
        );
        let result;
        switch (response.status) {
          case 200:
            result = await response.json();
            localStorage.setItem('session_token', result.accessToken);
            dispatch(setUserLogInFlag(true));
            if (userSignUp) {
              onChangeMainMobileAuthCase('account created');
              setTimeout(() => {
                dispatch(setModalFlag({ modal: false, def: null }));
              }, DEFAULT_DELAY_TIME);
            } else {
              dispatch(setModalFlag({ modal: false, def: null }));
            }
            break;
          case 422:
            result = await response.json();
            setInputCounter(result.counter);
            setLoginError(true);
            if (inputCounter < 5) {
              setLoginErrorText(
                `Для корректного ввода кода остолось ${
                  inputCounter - 1
                } попыток.`,
              );
            } else {
              setLoginErrorText('Введен неверный код');
            }
            if (inputCounter - 1 === 0) {
              if (userSignUp) {
                onChangeMainMobileAuthCase('registration is blocked');
              } else {
                onChangeMainMobileAuthCase('entrance is blocked');
              }
            }
            break;
          case 429:
            if (userSignUp) {
              onChangeMainMobileAuthCase('registration is blocked');
            } else {
              onChangeMainMobileAuthCase('entrance is blocked');
            }
            break;
          default:
            dispatch(
              setErrorModal({
                errorCode: response.status,
                fullwidth: false,
                itemId: null,
              }),
            );
            dispatch(setModalFlag({ modal: true, def: 'error' }));
            break;
        }
      } catch (error) {
        dispatch(
          setErrorModal({
            errorCode: null,
            fullwidth: false,
            itemId: null,
          }),
        );
        dispatch(setModalFlag({ modal: true, def: 'error' }));
      }
    },
    [dispatch, inputCounter, userSignUp],
  );

  const handleMessageCodeChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length > 3) {
        setLoginError(false);
        if (!userSignUp) {
          await handleCodeConfirmation(
            LOGIN_CONFIRM_URL,
            e.target.value,
          );
        } else {
          await handleCodeConfirmation(
            REG_CONFIRM_URL,
            e.target.value,
          );
        }
      }
    },
    [userSignUp],
  );

  useEffect(() => {
    const timer =
      Boolean(counter > 0 && isAuthentication) &&
      setInterval(() => setCounter((prev) => prev - 1), 1000);

    // @ts-ignore
    return () => clearInterval(timer);
  }, [counter, isAuthentication]);

  useEffect(() => {
    if (Number(shipmentCounter) === 0 && isAuthentication) {
      onShipmentFlagToggle(false);
    }
  }, []);

  return (
    <form
      autoComplete="new-password"
      action=""
      onSubmit={
        isAuthentication
          ? handleMobileCodeRetry
          : handleMobileAuthorization
      }
    >
      <div className="d-flex flex-column position-relative">
        {isAuthentication ? (
          <MobileAuthenticationInput
            onChange={handleMessageCodeChange}
          />
        ) : (
          <MobileAuthorizationInput
            onChange={handleMobilePhoneChange}
            onFocus={handleInputFocus}
            mobilePhone={mobilePhone}
          />
        )}
        {Boolean(loginError) && (
          <p className={styles['error-text']}>{loginErrorText}</p>
        )}
      </div>
      {isAuthentication ? (
        <p className={styles.tooltip}>
          Введите код из SMS, отправленного на номер {mobilePhone}
        </p>
      ) : null}
      <Row>
        <Col>
          <div className={`position-relative ${styles['mt-3']}`}>
            {shipmentFlag ? (
              <Button
                type="submit"
                disabled={counter !== 0 && isAuthentication}
                testId="mobile_authorization-button-1"
                styleType="primary"
                size="large"
                label={
                  isAuthentication
                    ? `Выслать код заново ${
                        counter === 0 ? '' : `(${counterBtnValue})`
                      }`
                    : authButtonText
                }
                product="ofd"
                loading={isLoginLoading}
                onClick={() => null}
                theme="common"
              />
            ) : (
              <Button
                type="submit"
                disabled={
                  (counter !== 0 && isAuthentication) || !shipmentFlag
                }
                testId="mobile_authorization-button-2"
                styleType="primary"
                size="large"
                label="Лимит SMS исчерпан"
                product="ofd"
                loading={isLoginLoading}
                onClick={() => null}
                theme="common"
              />
            )}
            {isAuthentication && shipmentCounter < 3 ? (
              <div className={`text-center ${styles['mt-2']}`}>
                {shipmentFlag ? (
                  <div
                    className={`${styles['info-text']} ${styles['info-text-warning']}`}
                  >
                    Осталось {shipmentCounter} отправки
                  </div>
                ) : (
                  <div
                    className={`${styles['info-text']} ${styles['info-text-error']}`}
                  >
                    Вы превысили лимит запросов кода. Попробуйте через
                    час или обратитесь в службу поддержки:{' '}
                    <a
                      data-testid="mobile_authorization-link-1"
                      className="link"
                      href={supportHref}
                    >
                      support@podarocheck.ru
                    </a>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default MobileAuthorization;
