import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from './content-preloader.module.scss';

const cx = classNames.bind(styles);

const CouponFilterPreloader: FC = memo(() => (
  <div>
    <ul className={cx('coupon-list-placeholder')}>
      {Array.from(Array(5)).map((item) => (
        <li key={item} className={cx('coupon-item-placeholder')}>
          <p className={cx('coupon-text-placeholder')} />
        </li>
      ))}
    </ul>
  </div>
));

CouponFilterPreloader.displayName = 'CouponFilterPreloader';

export default CouponFilterPreloader;
