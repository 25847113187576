import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorCode: null,
  fullwidth: false,
  itemId: null,
};

const errorModalSlice = createSlice({
  name: 'error-modal',
  initialState,

  reducers: {
    setErrorModal(state, action) {
      state.errorCode = action.payload.errorCode;
      state.fullwidth = action.payload.fullwidth;
      state.itemId = action.payload.itemId;
    },
  },
});

export const { setErrorModal } = errorModalSlice.actions;

export default errorModalSlice.reducer;
