import { useCallback } from 'react';
import { FIND_POSITION, GET_POSITION, SAVE_POSITION } from 'urls';

const useSetPosition = () => {
  const getPosition = useCallback(async () => {
    try {
      const res = await fetch(`${GET_POSITION}`, {
        headers: {
          Authorization: localStorage.session_token,
        },
      });

      if (res.status === 200) {
        return await res.json();
      }

      return null;
    } catch (err) {
      throw new Error(
        'Ошибка: не удалось загрузить список документов',
      );
    }
  }, []);

  const getCurrentPosition = useCallback(
    async (latitude: number, longitude: number) => {
      try {
        // @ts-ignore
        const res = await fetch(
          `${FIND_POSITION}/${latitude}/${longitude}`,
          {
            headers: {
              Authorization: localStorage.session_token,
            },
          },
        );

        if (res.status === 200) {
          const pos = await res.json();
          return pos;
        }

        return null;
      } catch (err) {
        throw new Error(
          'Ошибка: не удалось загрузить список документов',
        );
      }
      return null;
    },
    [],
  );

  const saveNewPosition = useCallback(async (id: number) => {
    try {
      const res = await fetch(`${SAVE_POSITION}/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.session_token,
        },
      });

      return res;
    } catch (err) {
      throw new Error(
        'Ошибка: не удалось загрузить список документов',
      );
    }
  }, []);

  return { getCurrentPosition, getPosition, saveNewPosition };
};

export default useSetPosition;
