import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './no-found-404.module.scss';
import notFoundIcon from '../images/error_404.svg';

const NotFound404 = () => (
  <div className={`${styles['not-found']}`}>
    <Container
      className={`${styles.container} d-flex align-items-center`}
    >
      <div className={styles.main}>
        <div className={styles.pic}>
          <img src={notFoundIcon} alt="ничего не найдено" />
        </div>
        <div className={styles.desc}>
          <div className={styles.title}>CТРАНИЦА НЕ НАЙДЕНА</div>
          <p className={styles['mt-1']}>
            Возможно, вы воспользовались недействительной ссылкой или
            страница была удалена.
          </p>
          <p className={styles['mt-1']}>
            Перейдите на главную
            <Link
              data-testid="not_found_404-nav-link-1"
              to="/"
              className="link"
            >
              страницу
            </Link>
            или воспользуйтесь поиском.
          </p>
        </div>
      </div>
    </Container>
  </div>
);

export default NotFound404;
