import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  posObjData: {
    cityName: '',
    regionCode: '',
    regionName: '',
  },
};

const userPositionSlice = createSlice({
  name: 'userPosition',
  initialState,

  reducers: {
    setPosition(state, action) {
      state.posObjData = action.payload;
    },
  },
});

export const { setPosition } = userPositionSlice.actions;

export default userPositionSlice.reducer;
