import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TCouponsAndCategoriesListTypes from './models/couponsAndCategoriesList.model';
import { ICoupons, IShopCategories } from '../interfaces';

const initialState: TCouponsAndCategoriesListTypes = {
  couponList: [],
  groupCoupons: [],
  shopCategories: [],
  newShopCategories: [],
  accessKey: '',
  isCouponsLoading: false,
  isError: false,
  currentShopCategory: null,
};

const couponsAndCategoriesListSlice = createSlice({
  name: 'coupons and categories',
  initialState,

  reducers: {
    setIsLoading(state, action) {
      state.isCouponsLoading = action.payload;
    },

    setCouponsList(state, action: PayloadAction<ICoupons[]>) {
      state.couponList = action.payload;
    },

    groupCouponsList(state, action: PayloadAction<ICoupons[]>) {
      state.groupCoupons = action.payload;
    },

    setAccessKey(state, action: PayloadAction<string>) {
      state.accessKey = action.payload;
    },

    setShopCategories(
      state,
      action: PayloadAction<IShopCategories[]>,
    ) {
      state.shopCategories = action.payload;
    },

    setNewShopCategories(
      state,
      action: PayloadAction<IShopCategories[]>,
    ) {
      state.newShopCategories = action.payload;
    },

    setErrorStatus(state, action) {
      state.isError = action.payload;
    },

    setCurrentShopCategory(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.currentShopCategory = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setCouponsList,
  setAccessKey,
  setShopCategories,
  groupCouponsList,
  setErrorStatus,
  setCurrentShopCategory,
  setNewShopCategories,
} = couponsAndCategoriesListSlice.actions;

export default couponsAndCategoriesListSlice.reducer;
