import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from './content-preloader.module.scss';

const cx = classNames.bind(styles);

const ContentPreloader: FC = memo(() => (
  <div>
    <div
      className={cx(
        'placeholder-wrapper',
        'placeholder-product-list',
      )}
    >
      {Array.from(Array(12)).map((item: number) => (
        <div key={item} className={cx('placeholder-product-item')}>
          <div className={cx('base-placeholder', 'image-medium')} />
          <div
            className={cx(
              'base-placeholder',
              'paragraph-placeholder',
            )}
          />
          <div className={styles.divider}>
            <div className={styles.left_divider}>
              <div />
            </div>
            <div className={styles.middle_divider} />
            <div className={styles.right_divider}>
              <div />
            </div>
          </div>
          <div
            className={cx('base-placeholder', 'placeholder-title')}
          />
          <div
            className={cx(
              'base-placeholder',
              'paragraph-placeholder',
            )}
          />
        </div>
      ))}
    </div>
  </div>
));

ContentPreloader.displayName = 'ContentPreloader';

export default ContentPreloader;
