import React, { useEffect, useState } from 'react';
import detect from 'detect.js';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import QrCode from 'react-qr-code';
import { COUPONS_ALL_ID } from '../utils';
import {
  googlePlayLink,
  appleStoreLink,
} from '../shared/constants/constatnts';

import redirectPageLogo from '../images/icons/redirect-page-logo.svg';
import redirectPageImg from '../images/icons/redirect-page-img.svg';
import googlePlayIcon from '../images/icons/google-play-icon.svg';
import appleStoreIcon from '../images/icons/app-store-icon.svg';

import styles from './redirect-page.module.scss';

const mobileViewport = 478;

const RedirectPage = () => {
  const [appleLinkFlag, setAppleLinkFlag] = useState(true);
  const [googleLinkFlag, setGoogleLinkFlag] = useState(true);

  useEffect(() => {
    const agent = detect.parse(navigator.userAgent);
    if (window.innerWidth < mobileViewport) {
      switch (agent.os.family) {
        case 'Android':
          setAppleLinkFlag(false);
          window.location.href = googlePlayLink;
          break;
        case 'iOS':
          setGoogleLinkFlag(false);
          window.location.href = appleStoreLink;
          break;
        default:
      }
    }
  }, []);

  return (
    <div className={styles.wrap}>
      <Helmet title="ПодароЧек — скидки, акции и подарки">
        <meta
          name="description"
          content="Промокоды, скидки и акции. Экономьте на покупках в магазинах, супермаркетах и онлайн-сервисах. На сайте и в мобильном приложении."
        />
      </Helmet>
      <div />
      <Container className={styles.container}>
        <div className={styles.main}>
          <div className={styles.block}>
            <div className={styles}>
              <img src={redirectPageLogo} alt="logo" />
            </div>
            <p className={styles.title}>
              Экономьте удобнее с&nbsp;приложением
            </p>
            <p className={styles.subtitle}>
              Скидки, акции и подарки всегда у&nbsp;вас
              в&nbsp;смартфоне
            </p>
            <div className={styles.links}>
              <div className={`${styles.qrcode} d-md-block d-none`}>
                <QrCode
                  value={`https://4310416.redirect.appmetrica.yandex.com/podarocheck.ru/?banner_id=${String(
                    COUPONS_ALL_ID,
                  )}&appmetrica_tracking_id=965007236847896906`}
                  size={202}
                  viewBox="0 0 231 231"
                />
              </div>
              <div>
                <p
                  className={`${styles.text} mb-3 d-none d-md-block`}
                >
                  Наведите камеру на QR-код, чтобы скачать
                </p>
                <div
                  className={`${styles.badges} ${
                    !googleLinkFlag || !appleLinkFlag
                      ? styles.fullwidth
                      : ''
                  }`}
                >
                  {googleLinkFlag ? (
                    <a
                      data-testid="redirect_page-link-1"
                      href={googlePlayLink}
                    >
                      <img src={googlePlayIcon} alt="google play" />
                    </a>
                  ) : null}
                  {appleLinkFlag ? (
                    <a
                      data-testid="redirect_page-link-2"
                      href={appleStoreLink}
                    >
                      <img src={appleStoreIcon} alt="apple store" />
                    </a>
                  ) : null}
                </div>
                <Link
                  data-testid="redirect_page-nav-link-1"
                  className={`${styles.link} d-none d-md-block`}
                  to="/?act_page=redirect_store"
                >
                  Продолжить на сайте
                </Link>
              </div>
            </div>
          </div>
          <div>
            <img
              className={styles.pic}
              src={redirectPageImg}
              alt="redirect page logo"
            />
          </div>
          <div
            className={`${styles['mobile-link']} d-block d-md-none`}
          >
            <div className={styles['mobile-link-wrap']}>
              <Link
                data-testid="redirect_page-nav-link-2"
                to="/?act_page=redirect_store"
              >
                Продолжить на сайте
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RedirectPage;
