import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setErrorModal } from '../../store/errorModalSlice';
import { setModalFlag } from '../../store/modalSlice';
import { GET_EMAIL_CONFIRMED_STATUS } from '../../urls';

const useEmailConfirmedStatus = () => {
  const dispatch = useDispatch();
  const getEmailConfirmedStatus = useCallback(async () => {
    try {
      const getStatus = await fetch(GET_EMAIL_CONFIRMED_STATUS, {
        headers: {
          Authorization: localStorage.session_token,
        },
      });
      return getStatus;
    } catch (err) {
      dispatch(
        setErrorModal({
          errorCode: null,
          fullwidth: false,
          itemId: null,
        }),
      );
      dispatch(setModalFlag({ modal: true, def: 'error' }));
      throw new Error('Ошибка запроса статуса');
    }
  }, [dispatch]);

  return { getEmailConfirmedStatus };
};

export default useEmailConfirmedStatus;
