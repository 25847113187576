import React from 'react';
import { Helmet } from 'react-helmet';

import './terms-page.module.scss';

const TermsPage = () => (
  <section
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Helmet title="Пользовательское соглашение">
      <meta
        name="description"
        content="Страница пользовательского соглашения"
      />
    </Helmet>
    <h2>Пользовательское соглашение</h2>
    <section>
      <h3>1. Термины и определения</h3>
      <p>
        1.1. Сервис - Сервис «ПодароЧек» (https://podarocheck.ru/),
        включая мобильное приложение для смартфонов под управлением
        операционных систем iOS и Android «ПодароЧек» (Сайт и
        Программа). Далее по тексту настоящего Пользовательского
        соглашения все, что упоминается в отношении Сайта или
        Программы, в равной степени относится ко всем составляющим
        Сервиса.
      </p>
      <p>
        1.2. Программа — программное обеспечение (мобильное
        приложение) «ПодароЧек», предназначенное для быстрого доступа
        в разделы сайта https://podarocheck.ru/. Правообладателем
        Программы является ООО «Эвотор ОФД».
      </p>
      <p>
        1.3. Пользователь — физическое лицо, действующее в своих
        интересах, зарегистрированное в Сервисе и принявшее все
        положения данного Соглашения.
      </p>
      <p>
        1.4. Администрация Сервиса, Общество — Общество с ограниченной
        ответственностью «Эвотор ОФД» (ОГРН 1167746512856, ИНН
        9715260691, КПП 770401001), Местонахождение Общества: 119048,
        г. Москва, ул. Усачева, д. 33, стр.1
      </p>
      <p>
        1.5. Заявка на идентификацию — документ, формируемый
        потенциальным Пользователем Сервиса, действующим своей волей и
        в своем интересе, на идентификацию в Сервисе, на присоединение
        и принятие всех условий настоящего Соглашения, при условии
        присоединения и принятия всех условий Пользовательского
        соглашения, Правил использования cookie, а также
        предоставляющий согласие на обработку Обществом, в т.ч. с
        помощью Программы, в соответствии с Политикой обработки
        персональных данных Пользователя, определяемых на основании
        законодательства Российской Федерации, начиная с момента
        первого использования Программы и до истечения сроков хранения
        соответствующей информации или документов, содержащих
        вышеуказанную информацию.
      </p>
      <p>
        1.6. Аккаунт — личный кабинет Пользователя Сервиса,
        размещенный на сервере Общества, в котором хранится вся
        информация о Пользователе, обрабатываемая в Сервисе. Доступ в
        Аккаунт имеет только зарегистрированный Пользователь.
      </p>
      <p>
        1.7. Зона технической ответственности Общества — технические
        средства и каналы, находящиеся во владении и под управлением
        Общества, за которые Общество несет ответственность. Зона
        ответственности Общества заканчивается внешней границей
        (стеной) помещения, в котором установлено оборудование
        Администрации Сервиса.
      </p>
      <p>
        1.8. Авторизация в Сервисе – действия Пользователя,
        направленные на осуществление доступа в Аккаунт путем ввода
        уникальной пары Логин/Пароль от Аккаунта Пользователя, при
        этом Логин является постоянным, а Пароль генерируется в
        порядке, установленном в п. 3.4. настоящего Соглашения,
        направляется Пользователю в форме СМС-сообщения и является
        одноразовым.
      </p>
      <p>
        1.9. Персональные данные — любая информация, относящаяся прямо
        или косвенно к определенному или определяемому физическому
        лицу (субъекту персональных данных).
      </p>
      <p>
        1.10. Обработка персональных данных — любое действие
        (операция) или совокупность действий (операций), совершаемых с
        использованием средств автоматизации или без использования
        таких средств с персональными данными.
      </p>
      <p>
        1.11. Сайт Сервиса (Сайт) – публично доступный, принадлежащий
        Обществу, информационный ресурс, размещенный в
        информационно-телекоммуникационной сети «Интернет» по адресу
        https://podarocheck.ru/.
      </p>
      <p>
        1.12. Использование Программы - совершение Пользователем любых
        действий в интерфейсе Программы после Авторизации в ней.
      </p>
      <p>
        1.13. Промокод - код, посредством использования которого
        пользователю может быть предоставлена скидка при заказе товара
        или услуги у одного из наших партнеров. Условия
        предоставления/пользования промокодами определяются продавцом
        соответствующего товара (услуги).
      </p>
      <p>
        1.14. Партнер - юридическое лицо или индивидуальный
        предприниматель, в лице специально уполномоченного физического
        лица, представляющего интересы юридического лица на законном
        основании, осуществляющие деятельность по продаже
        товаров/услуг посредством сети Интернет, и предоставляющие
        промокоды лицам, осуществившим заказ товара/услуги на
        интернет-сайтах/мобильных приложениях партнеров.
      </p>
      <p>
        1.15. Рассылка - направление Пользователям
        рекламно-информационных материалов Партнеров.
      </p>
    </section>
    <section>
      <h3>2. Общие положения</h3>
      <p>
        2.1. Настоящее Пользовательское соглашение (Соглашение)
        регулирует отношения Общества (Администрации Сервиса) и
        Пользователей по поводу использования и предоставления доступа
        к Сервису через Сайт или Программу.
      </p>
      <p>
        2.2. Сторонами данного Соглашения являются правообладатель
        Сервиса — ООО «Эвотор ОФД» и Пользователи.
      </p>
      <p>
        2.3. Настоящие условия Соглашения являются едиными для всех
        Пользователей Сервиса. Пользователь Сервиса в соответствии со
        ст. 428 ГК РФ безоговорочно присоединяется к настоящему
        Соглашению, регистрируясь на сайте https://podarocheck.ru/
        и/или через авторизацию в Программе.
      </p>
      <p>
        2.4. Общество предоставляет доступ в Программе к сервисам и
        Аккаунту на сайте https://podarocheck.ru/ для
        зарегистрированных Пользователей посредством мобильных и иных
        устройств, как существующих в настоящее время, так и тех,
        которые будут разработаны в будущем, с учетом особенностей
        сервисов.
      </p>
      <p>
        2.5. Общество обрабатывает учетные данные и иные данные в
        целях надлежащего исполнения настоящего Соглашения.
      </p>
      <p>
        2.6. В целях исполнения настоящего Соглашения и предоставления
        Пользователю доступа к использованию Сервиса, включая
        функционал Программы, Общество развивает, совершенствует,
        оптимизирует и внедряет новый функционал в Сервис (включая
        сервисы и продукты информационного, коммуникационного,
        рекламного, образовательного, развлекательного и иного
        характера), в том числе с участием аффилированных лиц и/или
        партнеров. Для обеспечения реализации указанных целей
        Пользователь соглашается и поручает Обществу осуществлять с
        соблюдением применимого законодательства обработку (включая
        сбор, запись, систематизацию, накопление, хранение, уточнение
        (обновление, изменение), сопоставление, извлечение,
        использование, обезличивание, блокирование, удаление и
        уничтожение) учетных и иных данных Пользователя, включая
        результаты автоматизированной обработки таких данных, в том
        числе в виде целочисленных и/или текстовых значений и
        идентификаторов, их передачу аффилированным лицам и/или
        партнерам во исполнение такого поручения на обработку, а также
        осуществлять сбор (получение) его учетных и иных данных от
        аффилированных лиц и/или партнеров. Под учетными данными
        понимаются сведения, которые Пользователь предоставляет
        самостоятельно на этапе авторизации в Сервисе, использования
        Сервиса путем заполнения соответствующих форм. Под иными
        данными понимаются связанные с Пользователем данные, которые
        становятся доступными Обществу в процессе использования
        Пользователем функционала Программы и Сервиса в целом. Такие
        данные могут включать в себя, в том числе, информацию о
        технических средствах (устройствах) и способах
        технологического взаимодействия с сервисами Сервиса Общества
        и/или сервисами аффилированных лиц и/или партнеров (в т. ч.
        IP-адрес, вид операционной системы Пользователя, тип браузера,
        географическое положение, данные о провайдере и иное), об
        активности Пользователя, а также иные данные, получаемые
        указанными способами. Обществу может быть доступна иная
        информация, относящаяся к Пользователю и оставленная последним
        по своему усмотрению в процессе использования Сервиса, которая
        не обрабатывается Обществом, в том числе для достижения
        указанных в настоящем Соглашении целей. Обработка учетных и
        иных данных Пользователя осуществляется в течение всего
        периода времени с момента регистрации Пользователя в Сервисе и
        до момента удаления его Аккаунта, если иное не обусловлено
        функционалом Сервиса и/или не предусмотрено действующим
        законодательством. В целях надлежащего исполнения условий
        настоящего Соглашения, Общество принимает меры по обеспечению
        безопасности Сервиса. Для реализации указанных целей
        Пользователь соглашается, что учетные и иные данные могут быть
        переданы третьим лицам, в том числе в случаях, предусмотренных
        применимым законодательством, в объеме, необходимом для
        выявления, расследования и пресечения противоправных действий.
      </p>
      <p>
        2.7. В случае несогласия Пользователя с условиями настоящего
        Соглашения использование Сервиса и его функционала должно быть
        немедленно прекращено Пользователем.
      </p>
      <p>
        2.8. При использовании Пользователем Сервиса может применяться
        технология cookies. Пользователь вправе ограничить или
        запретить использование технологии cookies путем применения
        соответствующих настроек.
      </p>
    </section>
    <section>
      <h3>3. Порядок регистрации в Сервисе</h3>
      <p>
        3.1. Пользователь обращается к Обществу с Заявкой на
        идентификацию в Сервисе. Указанная заявка формируется и
        направляется Обществу с помощью локальной версии Программы,
        установленной на мобильном или ином устройстве Пользователя,
        или путем заполнения формы, размещенной на Сайте.
      </p>
      <p>
        3.2. При отправке Заявки Пользователь дает согласие на
        обработку его персональных данных в соответствии с Политикой
        обработки персональных данных.
      </p>
      <p>
        3.3. Общество осуществляет идентификацию Пользователя в
        Сервисе на основании авторизации в Сервисе с применением
        Логина/Пароля Аккаунта.
      </p>
      <p>
        3.4. При регистрации и Авторизации Пользователь указывает
        номер мобильного телефона, на который Общество отправляет
        СМС-сообщение. В СМС-сообщении содержится одноразовый код,
        который Пользователь должен ввести в форму для кода из SMS.
        После успешного ввода кода Пользователь регистрируется или
        авторизуется в Сервисе.
      </p>
      <p>
        3.5. Пользователь соглашается с тем, что Общество может
        осуществлять сбор обезличенных статистических данных о
        Пользователях Сервиса.
      </p>
      <p>
        3.6. Общество вправе устанавливать любые дополнительные
        требования и ограничения применительно к использованию
        Сервиса.
      </p>
      <p>
        3.7. В целях использования Программы как совокупности сервисов
        Общество предоставляет Пользователю (лицензиату) на условиях
        простой (неисключительной) безвозмездной лицензии право
        использования функциональных (программных) возможностей
        Программы.
      </p>
    </section>
    <section>
      <h3>4. Порядок использования Программы и Сайта</h3>
      <p>
        4.1. Необходимым условием использования Пользователем
        Программы является:
      </p>
      <p>
        4.1.1. подключение мобильного и иного устройства Пользователя
        к информационно-телекоммуникационной сети «Интернет»;
      </p>
      <p>
        4.1.2. соответствие мобильного и иного устройства
        Пользователя, предназначенного для работы в Программе,
        опубликованным техническим требованиям.
      </p>
      <p>
        4.2. После реализации пп. 3.1—3.5 и при выполнении условий в
        п.4.1 у Пользователя появляется возможность использовать
        Программу в соответствии с ее назначением, имеющимися у него
        лицензиями и действующим законодательством РФ.
      </p>
      <p>
        4.3. Пользователь обязуется использовать Программу строго в
        соответствии с её описанием и назначением.
      </p>
      <p>
        4.4. В связи с тем, что безопасность определяется не только
        уровнем защиты Программы, в которой регистрируется
        Пользователь, но и уровнем защиты устройства/оборудования, с
        которого осуществляется доступ, для безопасной работы
        Пользователь должен следовать следующим рекомендациям: • —
        использовать исключительно лицензионное программное
        обеспечение на устройствах/оборудовании; • — устанавливать все
        необходимые обновления безопасности, рекомендуемые
        правообладателем программного обеспечения; • — устанавливать и
        регулярно обновлять лицензионное антивирусное программное
        обеспечение, регулярно проводить проверку на отсутствие
        вирусов; • — не загружать программ и данных из непроверенных
        источников, не посещать сайты сомнительного содержания; • — не
        заходить в Аккаунт со случайных компьютеров, интернет-кафе
        либо иных недоверенных рабочих мест и/или
        устройств/оборудования; • — не передавать кому-либо информацию
        для входа в Аккаунт, следить за сохранностью средств доступа.
      </p>
      <p>
        4.5. Пользователь ни самостоятельно, ни с привлечением третьих
        лиц не имеет права вскрывать, модифицировать и декомпилировать
        Программу, ее составные части или связанные с ней технологии.
      </p>
      <p>
        4.6. Все действия, совершенные Пользователем в Программе после
        его авторизации в Программе (по логину/паролю), по умолчанию
        считаются Обществом произведенными этим Пользователем лично и
        порождают для него соответствующие права и обязанности.
      </p>
      <p>
        4.7. Пользователь, размещая информацию в Аккаунте с помощью
        функционала Программы, предоставленной Пользователю в
        соответствии с Соглашением, осознает и соглашается с тем, что
        обработка внесенной им информации, в том числе персональных
        данных, производится непосредственно Обществом.
      </p>
      <p>
        4.8. Любое использование Программы, кроме разрешенного
        настоящим Соглашением, или в случае явно выраженного согласия
        Общества на такое использование, без предварительного
        письменного разрешения Общества, категорически запрещено.
      </p>
      <p>
        4.9. Пользователь, используя функционал Программы, соглашается
        с тем, что информация Пользователя может быть передана
        партнерам Общества исключительно в целях обеспечения оказания
        заказываемых Пользователем услуг, а равно предоставления
        Пользователям прав использования функциональных (программных)
        возможностей Программы, предоставляемых совместно с этими
        партнерами, и исключительно в объеме, необходимом для
        надлежащего оказания этих услуг и предоставления указанных
        прав.
      </p>
      <p>
        4.10. Пользователь признает, что Общество может устанавливать
        ограничения в использовании Программы, в том числе: срок
        хранения материалов, размещаемых Пользователем, максимальное
        количество данных, которые могут быть отправлены или получены
        одним Пользователем, максимальное количество обращений к
        Программе и её сервисам за определенный период времени и др.
      </p>
      <p>4.11. Сайт</p>
      <p>
        4.11.1. Пользователь соглашается не предпринимать действий и
        не оставлять комментарии и записи, которые могут
        рассматриваться как нарушающие российское законодательство или
        нормы международного права, в том числе в сфере
        интеллектуальной собственности, авторских и/или смежных прав,
        общепринятые нормы морали и нравственности, а также любых
        действий, которые приводят или могут привести к нарушению
        нормальной работы Сайта и Сервиса.
      </p>
      <p>
        4.11.2. Использование материалов Сайта без согласия
        правообладателей не допускается
      </p>
      <p>
        4.11.3. При цитировании материалов Сайта, включая охраняемые
        авторские произведения, ссылка на Сайт обязательна.
      </p>
      <p>
        4.11.4. Администрация Сайта не несет ответственности за
        посещение и использование им внешних ресурсов, ссылки на
        которые могут содержаться на Сайте.
      </p>
      <p>
        4.11.5. Администрация Сайта не несет ответственности и не
        имеет прямых или косвенных обязательств перед Пользователем в
        связи с любыми возможными или возникшими потерями или
        убытками, связанными с любым содержанием Сайта, регистрацией
        авторских прав и сведениями о такой регистрации, товарами или
        услугами, доступными на или полученными через внешние сайты
        или ресурсы либо иные контакты Пользователя, в которые он
        вступил, используя размещенную на Сайте информацию или ссылки
        на внешние ресурсы.
      </p>
      <p>
        4.11.6. Пользователь согласен с тем, что Администрация Сайта
        не несет какой-либо ответственности и не имеет каких-либо
        обязательств в связи с рекламой, которая может быть размещена
        на Сайте.
      </p>
    </section>
    <section>
      <h3>5. Права и обязанности сторон</h3>
      <p>5.1. Общество обязано:</p>
      <p>
        5.1.1. обеспечить функционирование Сервиса и доступность
        Программы, за исключением времени проведения профилактических
        работ, о которых Пользователь уведомляется не позднее, чем за
        24 (двадцать четыре) часа до их начала. Уведомление о
        проведении профилактических работ Общество направляет
        Пользователю посредством Личного кабинета Пользователя и/или
        путем размещения соответствующего уведомления на сайте
        Общества;
      </p>
      <p>
        5.1.2. в случае возникновения сбоев в работе Сервиса
        вследствие технических причин уведомить об этом Пользователя
        путем размещения информации на сайте Общества и устранить
        причины, если они находятся в зоне технической ответственности
        Общества;
      </p>
      <p>
        5.1.3. обеспечить информационно-консультационную и техническую
        поддержку Сервиса по каналам связи, указанным на Сайте, в
        рамках функционала, на который Пользователь имеет действующую
        лицензию.
      </p>
      <p>
        5.1.4. обеспечивать безопасность хранящейся конфиденциальной
        информации, в том числе и персональных данных (в рамках
        Аккаунта Оператором персональных данных, вводимых и
        обрабатываемых в нем, является Администрация Сервиса)
      </p>
      <p>5.2. Общество имеет право:</p>
      <p>
        5.2.1. вносить изменения и модификации в Сервис в любое время
        и по любой причине;
      </p>
      <p>
        5.2.2. изменять положения настоящего Соглашения, направив
        соответствующее уведомление Пользователю в Личном кабинете
        и/или разместив соответствующее уведомление на сайте Общества;
      </p>
      <p>
        5.2.3. вправе по первому требованию соответствующего
        уполномоченного правоохранительного и иного уполномоченного
        государственного органа, но в соответствии с действующим
        законодательством РФ, передавать такому государственному
        органу имеющуюся информацию о Пользователе.
      </p>
      <p>5.3. Пользователь обязан:</p>
      <p>
        5.3.1. ознакомиться с пользовательской информацией о Сервисе и
        выполнять ее предписания;
      </p>
      <p>
        5.3.2. соблюдать требования к обеспечению информационной
        безопасности;
      </p>
      <p>
        5.3.3. самостоятельно осуществлять управление
        (администрирование) своим Аккаунтом;
      </p>
      <p>
        5.3.4. в случае изменения реквизитов, указанных при
        регистрации в Сервисе, уведомить Общество;
      </p>
      <p>
        5.3.5. соблюдать требования всех нормативных актов,
        регламентирующих соответствующие виды электронного
        документооборота;
      </p>
      <p>
        5.3.6. самостоятельно и своевременно выполнять мониторинг
        поступающих в его адрес в Сервисе уведомлений, извещений и пр.
        и знакомиться с их содержимым;
      </p>
      <p>
        5.3.7. не воспроизводить, не копировать, не модифицировать, не
        продавать, не доводить до всеобщего сведения, не
        распространять контент Сервиса, целиком либо по частям, за
        исключением случаев, когда Соглашением, либо условиями
        пользования каким-либо сервисом предусмотрено иное.
      </p>
      <p> 5.4. Пользователь имеет право:</p>
      <p>
        5.4.1. получать доступ к Сервису, за исключением времени
        проведения профилактических и иных работ, объявленных
        Обществом согласно пунктам 5.1.1, 5.1.2; 5.4.2. вносить
        предложения по доработке Сервиса.
      </p>
    </section>
    <section>
      <h3>6. Ответственность сторон</h3>
      <p>6.1. Общество не несет ответственности:</p>
      <p>
        6.1.1. за содержание и достоверность информации,
        обрабатываемой и передаваемой с помощью Сервиса;
      </p>
      <p>
        6.1.2. за невозможность использования Сервиса, возникшую не по
        вине Общества;
      </p>
      <p>
        6.1.3. за действия Пользователя при использовании Сервиса,
        повлекшие за собой юридические и финансовые последствия в
        отношении третьих лиц.
      </p>
      <p>6.2. Пользователь понимает и соглашается с тем, что:</p>
      <p>
        6.2.1. Пользователь использует Сервис «как есть». Общество не
        принимает на себя никакой ответственности, в том числе за
        соответствие Сервиса целям Пользователя;
      </p>
      <p>
        6.2.2. Общество не гарантирует, что Сервис будет
        соответствовать требованиям Пользователя; будет
        предоставляться непрерывно, быстро, надежно и без ошибок;
        результаты, которые могут быть получены Пользователем, будут
        точными и надежными; качество какого-либо продукта, информации
        и прочего, полученного с использованием Сервиса, будут
        соответствовать ожиданиям Пользователя, и что все ошибки в
        Сервисе будут исправлены;
      </p>
      <p>
        6.2.3. любые материалы (объекты интеллектуальной
        собственности), полученные Пользователем с использованием
        Сервиса, Пользователь может использовать на свой собственный
        страх и риск, при этом на Пользователя возлагается
        ответственность за любой ущерб, который может быть нанесен
        оборудованию/устройству Пользователя и данным Пользователя в
        результате загрузки этих материалов;
      </p>
      <p>
        6.2.4. Общество не несет ответственности за любые прямые либо
        косвенные убытки, произошедшие из-за использования либо
        невозможности использования Сервиса; из-за
        несанкционированного доступа к
        коммуникациям/устройствам/оборудованию Пользователя; из-за
        мошеннической деятельности третьих лиц, включая использование
        обозначения либо товарного знака Общества в корыстных целях;
      </p>
      <p>
        6.3. Стороны освобождаются от ответственности за неисполнение
        или ненадлежащее исполнение условий настоящего документа в
        случае наступления обстоятельств непреодолимой силы
        (форс-мажор), определяемых в соответствии с действующим
        законодательством Российской Федерации, если они предъявляют
        доказательства того, что эти обстоятельства воспрепятствовали
        исполнению обязательств по настоящему документу. Такими
        доказательствами являются документы компетентных органов
        Российской Федерации. С момента устранения обстоятельств
        непреодолимой силы Соглашение действует в обычном порядке.
      </p>
      <p>
        6.4. В связи с использованием компьютерного, мобильного и
        иного оборудования/устройств, каналов связи и (или)
        программного обеспечения, принадлежащих третьим лицам, при
        использовании Сервиса Стороны соглашаются с тем, что Общество
        не несет ответственность за любые задержки, прерывания, прямой
        ущерб или упущенную выгоду, потери, происходящие из-за
        дефектов в любом электронном или механическом оборудовании и
        (или) программном обеспечении, либо вследствие иных
        объективных технологических причин, а также в результате
        действий или бездействий третьих лиц, проблем при передаче
        данных или соединении, перебоев в электропитании, произошедших
        не по вине Общества.
      </p>
      <p>
        6.5. В случае наступления форс-мажорных обстоятельств, а также
        аварий или сбоев в программно-аппаратных комплексах третьих
        лиц, сотрудничающих с Обществом, или действий третьих лиц,
        направленных на приостановку или прекращение функционирования
        всех или части сервисов Общества, Сервиса Общества, возможна
        приостановка работы Сервиса без предварительного уведомления
        Пользователей. 6.5. Общество не несет ответственности за
        исполнение обязательств по предоставлению скидок на основании
        предоставленных партнерами промокодов. Также Общество не несет
        ответственности за содержание рассылок, направляемых
        Партнерами
      </p>
      <p>
        6.6. В отношении услуг, оказываемых в рамках Соглашения на
        безвозмездной основе, нормы законодательства о защите прав
        потребителей, предусмотренные законодательством Российской
        Федерации, не могут быть применимы к отношениям между
        Пользователем и Обществом.
      </p>
      <p>
        6.7. Споры по данному Соглашению рассматриваются Сторонами в
        претензионном порядке, а при недостижении соглашения — в
        соответствии с законодательством РФ по месту нахождения
        Общества.
      </p>
    </section>
    <section>
      <h3>7. Действие Соглашения и порядок его изменения</h3>
      <p>
        7.1. Текст Соглашения и последующие изменения публикуются на
        сайте по адресу: https://podarocheck.ru/.
      </p>
      <p>
        7.2. Общество оставляет за собой право в одностороннем порядке
        вносить изменения и дополнения в настоящее Соглашение.
        Изменения вступают в силу с момента их публикации на Сайте
        Сервиса, если иной срок вступления изменений в силу не
        определен дополнительно при таком размещении. Настоящее
        Соглашение действует до размещения на сайте Общества
        официального извещения об его отзыве или размещении на Сайте
        новой версии Соглашения.
      </p>
      <p>
        7.3. Пользователь гарантирует, что все условия Соглашения ему
        понятны, и Пользователь принимает условия без оговорок и в
        полном объеме.
      </p>
      <p>
        7.4. Для всех действий Пользователя, совершаемых при
        использовании Сервиса, время их совершения устанавливается по
        Московскому времени (UTC+3).
      </p>
    </section>
    <section>
      <h3>8. Сайты третьих лиц</h3>
      <p>
        8.1. Сервис может содержать ссылки на другие сайты в сети
        Интернет (сайты третьих лиц). Указанные третьи лица и их
        контент не проверяются обществом на соответствие тем или иным
        требованиям (достоверности, полноты, законности и т. п.).
        Администрация сайта не несет ответственность за любую
        информацию, материалы, размещенные на сайтах третьих лиц, к
        которым Пользователь получает доступ с использованием Сервиса,
        в том числе, за любые мнения или утверждения, выраженные на
        сайтах третьих лиц, рекламу и т. п., а также за доступность
        таких сайтов или контента и последствия их использования
        Пользователем.
      </p>
      <p>
        8.2. Ссылка (в любой форме) на любой сайт, продукт, услугу,
        любую информацию коммерческого или некоммерческого характера,
        размещенная в Сервисе, не является одобрением или
        рекомендацией данных продуктов (услуг, деятельности) со
        стороны Общества, за исключением случаев, когда на это прямо
        указывается на ресурсе.
      </p>
    </section>
    <section>
      <h3>
        9. Адрес и реквизиты Общества Общество с ограниченной
        ответственностью «Эвотор ОФД» ОГРН 5157746008107 ИНН
        9715260691 КПП 770401001 Место нахождения:
      </h3>
      <p>
        119048, г. Москва, ул. Усачева, д.33, стр.1 Адрес для
        корреспонденции: 119048, г. Москва, ул. Усачева, д.33, стр.1
        р/с 40702810038000112679 в ПАО Сбербанк к/с
        30101810400000000225 БИК 044525225 Телефон: 7 (495) 252-50-50
        Дата публикации: 20 сентября 2022г.
      </p>
    </section>
  </section>
);

export default TermsPage;
