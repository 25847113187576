import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAuthorization from 'shared/hooks/use-authorization';
import { setModalFlag } from 'store/modalSlice';
import { setErrorModal } from 'store/errorModalSlice';
import { LOGIN_URL, REG_URL } from 'urls';
import AuthorizationInfoModalContent from '../authorization-info-modal-content/authorization-info-modal-content';

import Modal from '../modal/modal';
import MobileAuthorization from '../mobile-authorization/mobile-authorization';

import styles from './auth-modal.module.scss';

interface IAuthModal {
  userSignUp: boolean;
}

const AuthModal: FC<IAuthModal> = ({ userSignUp }) => {
  const [isAuthentication, setIsAuthentication] = useState(false);
  const [showBlockFlag, setShowBlockFlag] = useState(true);
  const [isCheckedSwitch] = useState('mobile');
  const [mainMobileAuthCase, setMainMobileAuthCase] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [infoModalShowFlag, setInfoModalShowFlag] = useState(false);
  const [startCounterFlag, setStartCounterFlag] = useState(true);
  const [shipmentCounter, setShipmentCounter] = useState(
    Number(localStorage.getItem('SHIPMENT_COUNTER')),
  );
  const [shipmentFlag, setShipmentFlag] = useState(true);
  const [serverDate, setServerDate] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { userLoginRequest } = useAuthorization();

  const handleServerDateSet = useCallback(async () => {
    await fetch(`${LOGIN_URL}`, {
      headers: {
        accept: 'application/hal+json',
        'content-type': 'application/json',
      },
      referrer: '',
      referrerPolicy: 'strict-origin-when-cross-origin',
      method: 'OPTIONS',
      mode: 'cors',
      credentials: 'omit',
    }).then((r) => setServerDate(r.headers.get('Date')));
  }, []);

  useEffect(() => {
    handleServerDateSet();
  }, []);

  const handleShipmentFlagToggle = (val: boolean) => {
    setShipmentFlag(val);
  };

  const handleChangeMainMobileAuthCase = (val: string) => {
    setMainMobileAuthCase(val);
    setShowBlockFlag(false);
    setInfoModalShowFlag(true);
  };

  const handleBtnClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    userSignUp
      ? dispatch(setModalFlag({ modal: true, def: 'auth' }))
      : dispatch(setModalFlag({ modal: true, def: 'reg' }));
  };

  const handleUserLogin = useCallback(
    async (url: string) => {
      const response = await userLoginRequest(
        url,
        serverDate,
        mobilePhone,
      );
      switch (response?.status) {
        case 200:
          // eslint-disable-next-line no-case-declarations
          const result = await response.json();
          localStorage.setItem('session_id', result.userId);
          setShipmentCounter(Number(result.counter));
          localStorage.setItem('SHIPMENT_COUNTER', result.counter);
          setIsAuthentication(true);
          if (result.counter === 0) {
            setShipmentFlag(false);
          }
          break;
        case 429:
          setIsAuthentication(true);
          setShipmentCounter(1);
          setShipmentFlag(false);
          break;
        case 403:
          handleChangeMainMobileAuthCase('existing account');
          break;
        case 404:
          handleChangeMainMobileAuthCase('account not registered');
          break;
        case 500:
          dispatch(setModalFlag({ modal: true, def: 'error' }));
          dispatch(
            setErrorModal({
              errorCode: response.status,
              fullwidth: false,
              itemId: null,
            }),
          );
          break;
        default:
          break;
      }
    },
    [serverDate, mobilePhone],
  );

  const handleToCodeInputReturn = async (val: string) => {
    setMainMobileAuthCase('');
    setInfoModalShowFlag(false);
    setIsAuthentication(true);
    switch (val) {
      case 'login':
        dispatch(setModalFlag({ modal: true, def: 'auth' }));
        await handleUserLogin(LOGIN_URL);
        break;
      case 'registration':
        dispatch(setModalFlag({ modal: true, def: 'reg' }));
        await handleUserLogin(REG_URL);
        break;
      default:
        setStartCounterFlag(false);
    }
  };

  const handleToPhoneInputReturn = () => {
    setMainMobileAuthCase('');
    setInfoModalShowFlag(false);
    setIsAuthentication(false);
    setShowBlockFlag(true);
  };

  const handleMobilePhoneChange = (val: string) => {
    setMobilePhone(val);
  };

  const headerText = userSignUp ? 'Зарегистрироваться' : 'Войти';

  return (
    <Modal
      headerText={headerText}
      fullWidth={false}
      onClose={() => {
        dispatch(setModalFlag({ modal: false, def: null }));
      }}
    >
      <div className={styles.modal}>
        {isCheckedSwitch === 'mobile' ? (
          <div>
            {infoModalShowFlag ? (
              <AuthorizationInfoModalContent
                mainMobileAuthCase={mainMobileAuthCase}
                mobilePhone={mobilePhone}
                onToPhoneInputReturn={handleToPhoneInputReturn}
                onToCodeInputReturn={handleToCodeInputReturn}
              />
            ) : (
              <MobileAuthorization
                startCounterFlag={startCounterFlag}
                userSignUp={userSignUp}
                mobilePhone={mobilePhone}
                onMobilePhoneChange={handleMobilePhoneChange}
                isAuthentication={isAuthentication}
                onUserLogin={handleUserLogin}
                shipmentCounter={shipmentCounter}
                shipmentFlag={shipmentFlag}
                onShipmentFlagToggle={handleShipmentFlagToggle}
                onChangeMainMobileAuthCase={
                  handleChangeMainMobileAuthCase
                }
              />
            )}
          </div>
        ) : null}
        {showBlockFlag ? (
          <div>
            <div
              className={`${styles['reg-link']} d-flex justify-content-center align-items-center`}
            >
              <span
                data-testid="auth_modal-click-1"
                aria-hidden
                onClick={handleBtnClick}
                className={`${styles.link} ms-1`}
                role="link"
              >
                {userSignUp
                  ? 'У меня есть аккаунт'
                  : 'Зарегистрироваться'}
              </span>
            </div>
            {userSignUp ? (
              <div className={`${styles.footnote} text-center`}>
                Нажимая «Зарегистрироваться », вы принимаете условия{' '}
                <a
                  target="_blank"
                  href="/terms"
                  data-testid="auth_modal-link-1"
                >
                  пользовательского соглашения
                </a>{' '}
                и{' '}
                <a
                  target="_blank"
                  href="/policy"
                  data-testid="auth_modal-link-2"
                >
                  политики конфиденциальности.
                </a>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default AuthModal;
