import React, { FC, useEffect } from 'react';
import styles from './overlay.module.css';

interface IOverlay {
  onClose: (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>
      | KeyboardEvent,
  ) => void;
}

const Overlay: FC<IOverlay> = ({ onClose }) => {
  const handleClose = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && onClose) onClose(e);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleClose);
    return () => window.removeEventListener('keydown', handleClose);
  });

  return (
    <div
      className={styles.overlay}
      onClick={onClose}
      aria-hidden
      data-testid="overlay-click-1"
    />
  );
};

export default Overlay;
