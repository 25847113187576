import { COUPONS_FIND } from 'urls';
import { GetNewCouponsTypes } from './models/search-coupons.model';

const getNewCoupons: GetNewCouponsTypes = async ({
  city_name,
  region_code,
  region_name,
  shopOrTitleName,
  page,
  access_key,
  size,
  banner_id,
}) => {
  const params = {
    city_name,
    region_code,
    region_name,
    page,
    access_key,
    size,
    shopOrTitleName,
    banner_id,
  };

  // @ts-ignore
  const qs = new URLSearchParams(params).toString();

  try {
    const res = await fetch(`${COUPONS_FIND}?${qs}`);
    if (res.status !== 200) {
      return false;
    }
    return await res.json();
  } catch (err) {
    throw new Error('Ошибка: не удалось загрузить список документов');
    return null;
  }
};

export default { getNewCoupons };
