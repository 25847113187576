import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './forgotPass.module.scss';
import logoImg from '../images/icons/logo-pcheck.svg';

const ForgotPass = () => (
  <div className={`${styles['forgot-pass']} d-flex`}>
    <Container className={styles.container}>
      <div
        className={`${styles.main} d-flex flex-column justify-content-center align-items-center`}
      >
        <div className="logo">
          <Link data-testid="forgot_pass-nav-link-1" to="/">
            <img
              className={styles['m-auto-md-0']}
              src={logoImg}
              alt="forgot pass logo"
            />
          </Link>
        </div>
        <div className={`${styles.block}  ${styles['mt-4']}`}>
          <div className="text">
            Укажите E-mail, на который был зарегистрирован аккаунт, и
            мы отправим на него ссылку для восстановления пароля.
          </div>
        </div>
        <div className={`${styles.block}  ${styles['mt-4']}`}>
          <form action="">
            <div
              className={`${styles['main-input']} d-flex flex-column`}
            >
              <label htmlFor="emailInput" className={styles.label}>
                Email
                <input
                  data-testid="forgot_pass-input-1"
                  type="email"
                  className={styles.input}
                  id="emailInput"
                />
              </label>
            </div>
            <div className={styles['mt-3']}>
              <button
                data-testid="forgot_pass-button-1"
                className={styles.btn}
                type="button"
              >
                Отправить
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  </div>
);

export default ForgotPass;
