import React from 'react';

import { Helmet } from 'react-helmet';
import styles from './policy-page.module.scss';

const PolicyPage = () => (
  <section className={styles.section}>
    <Helmet title="Политика конфиденциальности персональных данных">
      <meta
        name="description"
        content="Страница политики конфиденциальности персональных данных"
      />
    </Helmet>
    <h2 className={styles.title}>
      Политика конфиденциальности персональных данных
    </h2>
    <ol>
      <li>
        1. Основные понятия, используемые в Политике
        конфиденциальности персональных данных
        <ol>
          <li>
            1.1. Администрация Сервиса - уполномоченные сотрудники
            Общества с ограниченной ответственностью «Эвотор ОФД»
            (ОГРН 1167746512856, ИНН 9715260691) (далее – «Оператор»)
            на управление Сервисом, действующие от имени Оператора,
            которые организуют и (или) осуществляют обработку
            персональных данных, а также определяют цели обработки
            персональных данных, состав персональных данных,
            подлежащих обработке, действия (операции), совершаемые с
            персональными данными
          </li>
          <li>
            1.2. Сервис - Сервис «ПодароЧек»
            (https://podarocheck.ru/), включая мобильное приложение
            для смартфонов под управлением операционных систем iOS и
            Android «ПодароЧек», и иные интернет-сайты и сервисы,
            которые ссылаются на настоящую Политику конфиденциальности
            персональных данных, и через которые Оператор собирает
            персональные данные любых лиц, посещающих указанные
            Сервисы или сайты
          </li>
          <li>
            1.3. Пользователь Сервиса - лицо, имеющее доступ к Сервису
            посредством сети Интернет и использующее данный Сервис для
            своих целей (субъект персональных данных)
          </li>
          <li>
            1.4. Персональные данные - любая информация, относящаяся к
            прямо или косвенно определенному или определяемому
            физическому лицу - Пользователю Сервиса (субъекту
            персональных данных).
          </li>
          <li>
            1.5. Специальные категории персональных данных -
            персональные данные, касающиеся расовой, национальной
            принадлежности, политических взглядов, религиозных или
            философских убеждений, состояния здоровья, интимной жизни
          </li>
          <li>
            1.6. Конфиденциальность персональных данных - обязательное
            для соблюдения Администрацией Сервиса требование не
            раскрывать третьим лицам и не распространять персональные
            данные без согласия субъекта персональных данных, если
            иное не предусмотрено федеральным законом
          </li>
          <li>
            1.7. Обработка персональных данных - любое действие
            (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без
            использования таких средств с персональными данными,
            включая сбор, запись, систематизацию, накопление,
            хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление,
            доступ), обезличивание, блокирование, удаление,
            уничтожение персональных данных
          </li>
          <li>
            1.8. Обработка персональных данных с использованием
            средств автоматизации - обработка персональных данных с
            помощью средств вычислительной техники
          </li>
          <li>
            1.9. Распространение персональных данных - действия,
            направленные на раскрытие персональных данных
            неопределенному кругу лиц
          </li>
          <li>
            1.10. Предоставление персональных данных - действия,
            направленные на раскрытие персональных данных
            определенному лицу или определенному кругу лиц
          </li>
          <li>
            1.11. Блокирование персональных данных - временное
            прекращение обработки персональных данных (за исключением
            случаев, если обработка необходима для уточнения
            персональных данных)
          </li>
          <li>
            1.12. Уничтожение персональных данных - действия, в
            результате которых становится невозможным восстановить
            содержание персональных данных в информационной системе
            персональных данных и (или) в результате которых
            уничтожаются материальные носители персональных данных
          </li>
          <li>
            1.13. IP-адрес - уникальный сетевой адрес узла в
            компьютерной сети, построенной по протоколу IP
          </li>
          <li>
            1.14. Cookie - это небольшой текстовый файл, который
            веб-сервер размещает на жестком диске компьютера
            Пользователя. Сookie-файлы могут быть как «сеансовые», так
            и «постоянные»
          </li>
        </ol>
      </li>

      <li>
        2. Общие положения
        <ol>
          <li>
            2.1. Настоящая Политика конфиденциальности персональных
            данных ООО «Эвотор ОФД» (далее – Политика
            конфиденциальности) разработана Оператором (далее – ООО
            «Эвотор ОФД», Оператор, Администрация Сервиса) и
            применяется при использовании Сервиса «ПодароЧек» и иных
            интернет-сервисов, которые ссылаются на настоящую Политику
            конфиденциальности, и через которые Оператор собирает
            персональные данные любых лиц, посещающих указанные
            Сервисы.
          </li>
          <li>
            2.2. Политика конфиденциальности разработана в
            соответствии с требованиями Федерального закона от
            27.07.2006 № 152-ФЗ «О персональных данных» (далее –
            Федеральный закон «О персональных данных»), иных
            федеральных законов, регулирующих вопросы обработки
            персональных данных, а также принятых во их исполнение
            подзаконных нормативных правовых актов.
          </li>
          <li>
            2.3. Передавая Оператору персональные данные посредством
            использования Сервиса и регистрации в Сервисе,
            Пользователь дает свое согласие (добровольное и
            бессрочное) на использование персональных данных на
            условиях, изложенных в настоящей Политике
            конфиденциальности. Данное согласие является осознанным.
            Пользователь дает согласие на обработку персональных
            данных свободно, своей волей и в своем интересе. Согласие
            не является письменным, так как Оператор не обрабатывает
            специальные и биометрические персональные данные. Согласие
            дается в соответствии с п. 1 ст. 9 Федерального закона от
            27.07.2006 № 152-ФЗ (ред. от 22.07.2017) «О персональных
            данных».
          </li>
          <li>
            2.4. Если Пользователь не согласен с условиями настоящей
            Политики конфиденциальности, он обязан прекратить
            использование Сервисом.
          </li>
          <li>
            2.5. Безусловным акцептом настоящей Политики
            конфиденциальности является регистрация Пользователя в
            Сервисе.
          </li>
          <li>
            2.6. Политика конфиденциальности определяет основные цели
            и условия обработки персональных данных Пользователей
            Сервиса и сведения о реализуемых требованиях к защите
            персональных данных Пользователей Сервиса.
          </li>
          <li>
            2.7. Политика конфиденциальности применяется только к
            Сервису. По иным вопросам обработки персональных данных в
            ООО «Эвотор ОФД» разработаны и внедрены локальные акты.
            Администрация Сервиса не контролирует и не несет
            ответственность за Сервисы третьих лиц, на которые
            Пользователь Сервиса может перейти по ссылкам, доступным
            на Сервисе.
          </li>
          <li>
            2.8. Администрация Сервиса является оператором только тех
            персональных данных, которые получает от Пользователей
            Сервиса при использовании Сервиса с их согласия,
            предоставляемого путем совершения Пользователем Сервиса
            конклюдентных действий на Сервисе.
          </li>
          <li>
            2.9. Администрация Сервиса не проверяет достоверность
            персональных данных, предоставляемых Пользователем Сервиса
            на Сервисе. Администрация Сервиса исходит из того, что
            Пользователь Сервиса предоставляет достоверные и
            достаточные персональные данные и поддерживает эту
            информацию в актуальном состоянии. Всю ответственность за
            последствия предоставления недостоверных или
            недействительных персональных данных несет лично
            Пользователь Сервиса.
          </li>
          <li>
            2.10. Предоставляя свои персональные данные, Пользователь
            Сервиса соглашается с тем, что предоставленные им
            персональные данные будут обрабатываться ООО «Эвотор ОФД»
            (адрес: 119048, г. Москва, ул. Усачева, д.33, стр.1, ОГРН
            1167746512856, ИНН 9715260691) как с использованием
            средств автоматизации, так и без использования таких
            средств.
          </li>
          <li>
            2.11. Персональные данные, разрешённые к обработке в
            рамках Политики конфиденциальности, не являются
            специальными или биометрическими, предоставляются
            Пользователем Сервиса путём заполнения специальных форм в
            Сервисе или в процессе использования Сервиса бессрочно
            (вплоть до отзыва Пользователем Сервиса своего согласия на
            обработку персональных данных). В зависимости от
            совершаемых Пользователем действий, данные включают в себя
            следующую информацию:
            <ol>
              <li>2.11.1. имя и фамилию;</li>
              <li>2.11.2. пол;</li>
              <li>2.11.3. дата рождения;</li>
              <li>
                2.11.4. персональные данные Пользователя, получаемые
                при регистрации и авторизации Пользователя на Сервисе
                с использованием учетной записи Пользователя в соц.
                сетях, Apple ID или аккаунта Google;
              </li>
              <li>2.11.5. геолокация и город;</li>
              <li>2.11.6. адрес электронной почты;</li>
              <li>2.11.7. номер мобильного телефона;</li>
              <li>
                2.11.8. сведения о кассовых чеках - информация,
                содержащаяся в обычных (на бумажной кассовой ленте) и
                электронных чеках и их фото/скан образах. Заполняя
                соответствующие веб-формы и/или отправляя свои
                персональные данные Администрации Сервиса,
                Пользователь Сервиса выражает свое согласие с
                Политикой конфиденциальности.
              </li>
            </ol>
            <li>
              2.12. Администрация Сервиса также принимает меры по
              защите персональных данных, которые автоматически
              передаются в процессе посещения страниц Сервиса, в том
              числе из файлов cookie:
              <ol>
                <li>
                  2.12.1. IP адрес, присвоенный компьютеру
                  Пользователя в момент посещения Сервиса;
                </li>
                <li>2.12.2. Полученные данные о сессиях.</li>
              </ol>
              <li>
                2.13. Сookie-файлы могут быть как «сеансовые», так и
                «постоянные».
                <ol>
                  <li>
                    2.13.1. Сеансовые cookie-файлы Администрация
                    Сервиса использует сеансовые cookie-файлы, чтобы
                    присвоить уникальный идентификационный номер
                    компьютеру Пользователя Сервиса при каждом
                    посещении Сервиса, при этом после закрытия
                    браузера они удаляются. Такие файлы применяются
                    также для анализа работы Пользователя Сервиса с
                    Сервисом (изучается посещение страниц,
                    используемых ссылок и время пребывания
                    Пользователя на той или иной странице).
                  </li>
                  <li>
                    2.13.2. Постоянные cookie-файлы Сервис распознает
                    постоянные cookie, которые хранятся на жестких
                    дисках компьютеров Пользователей Сервиса, и,
                    присваивая устройствам Пользователей Сервиса
                    уникальные идентификаторы, Администрация Сервиса
                    может создать базу данных о действиях и
                    предпочтениях Пользователей Сервиса (в частности,
                    о частоте посещения и периодичности возвращения
                    Пользователей Сервиса, об их предпочтениях на
                    Сервисе). Важно, что cookie-файлы не содержат
                    личных данных Пользователей Сервиса, они только
                    фиксируют их действия.
                  </li>
                  <li>
                    2.13.3. Согласие на использование cookie Сеансовые
                    cookie-файлы не требуют предварительного согласия
                    Пользователей Сервиса; постоянные cookie-файлы
                    такого согласия требуют. Управлять cookie-файлами
                    Пользователи Сервиса могут через настройки
                    веб-браузера. Таким образом, у них есть
                    возможность разрешить использование всех
                    cookie-файлов, интегрированных в страницы или
                    заблокировать cookie-файлы, а также включить
                    использование cookie-файлов по запросу, принимать
                    или отклонять cookie-файлы. Управление
                    cookie-файлами различается в зависимости от
                    используемого браузера. При этом применяя
                    технологию cookie, Администрация Сервиса не хранит
                    и не использует никакие конкретные данные о
                    Пользователях Сервиса. Администрация Сервиса
                    обращает внимание, что Пользователь Сервиса вправе
                    настроить браузер на отказ от регистрации
                    обращений к Сервису или на предупреждения о
                    запросах на подобную регистрацию. Отключение
                    «cookie» может повлечь невозможность доступа к
                    Сервису.
                  </li>
                </ol>
                <li>
                  2.14. Если Администрация Сервиса не сможет никаким
                  образом соотнести информацию, указанную в пунктах
                  2.11-2.12 Политики конфиденциальности с
                  Пользователем Сервиса (физическим лицом),
                  Администрация Сервиса не будет считать данную
                  информацию персональными данными.
                </li>
              </li>
            </li>
          </li>
        </ol>
      </li>
      <li>
        3. Цели обработки персональных данных Пользователей Сервиса
        <ol>
          <li>
            3.1. Сервис собирает и хранит только те персональные
            данные Пользователя Сервиса, которые необходимы для
            предоставления сервисов или исполнения соглашений и
            договоров с Пользователем Сервиса, за исключением случаев,
            когда законодательством предусмотрено обязательное
            хранение персональных данных в течение определенного
            законом срока.
          </li>
          <li>
            3.2. Персональные данные Пользователя Сервис обрабатывает
            в следующих целях:
            <ol>
              <li>
                3.2.1. Предоставления Пользователям Сервиса
                возможности регистрации (регистрации учетной
                записи/авторизации) в Сервисе, доступа к Сервису,
                исполнение соглашений и договоров, и любых других
                случаях, связанных с такими действиями.
              </li>
              <li>
                3.2.2. Предоставления Пользователю Сервиса доступа к
                персонализированным ресурсам Сервиса.
              </li>
              <li>
                3.2.3. Установления с Пользователем Сервиса обратной
                связи, включая направление уведомлений, запросов, в
                том числе направление уведомлений, сообщений
                рекламного характера, запросов и информации,
                касающихся использования Сервиса, оказания услуг,
                обработку запросов и заявок от Пользователя Сервиса,
                посредством email-рассылок, sms-уведомлений,
                push-уведомлений на Сервисе и в мобильном приложении.
              </li>
              <li>
                3.2.5. Предоставления Пользователю Сервиса эффективной
                клиентской и технической поддержки при возникновении
                проблем, связанных с использованием Сервиса.
              </li>
              <li>
                3.2.6. Обеспечения работоспособности и безопасности
                Сервиса, для подтверждения совершаемых Пользователями
                Сервиса действий, для предотвращения случаев
                мошенничества, компьютерных атак и иных
                злоупотреблений, а также для расследования таких
                случаев.
              </li>
              <li>
                3.2.7. В аналитических, статистических, маркетинговых
                целях;
              </li>
              <li>
                3.2.8. Проведение статистических и иных исследований
                на основе обезличенных данных
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        4. Условия обработки персональных данных Пользователей Сервиса
        и передача их третьим лицам
        <ol>
          <li>
            4.1. Сервис хранит персональные данные Пользователей
            Сервиса в соответствии с внутренними регламентами
            конкретных сервисов.
          </li>
          <li>
            4.2. В отношении персональных данных Пользователя Сервиса
            сохраняется их конфиденциальность.
          </li>
          <li>
            4.3. Сервис вправе передать персональные данные
            Пользователя Сервиса третьим лицам в следующих случаях:
            <ol>
              <li>
                4.3.1. Передача необходима для использования
                Пользователем Сервиса определенного сервиса либо для
                исполнения определенного соглашения или договора с
                Пользователем Сервиса, для целей оказания Пользователю
                Сервиса услуг.
              </li>
              <li>
                4.3.2. Передача предусмотрена законодательством
                Российской Федерации в рамках установленной
                законодательством процедуры.
              </li>
            </ol>
          </li>
          <li>
            4.4. Настоящим Администрация Сервиса уведомляет
            Пользователя Сервиса, что в том случае, если Пользователь
            Сервиса пожелал внести уточнения в его персональные
            данные, заблокировать или уничтожить их в случае, если
            персональные данные являются неполными, устаревшими,
            неточными, незаконно полученными или не являются
            необходимыми для заявленной цели обработки, либо в случае
            возникновения у Пользователя желания отозвать свое
            согласие на обработку персональных данных или устранить
            неправомерные действия Администрации Сервиса в отношении
            персональных данных Пользователя Сервиса, то Пользователь
            Сервиса должен направить Администрации Сервиса официальный
            запрос по адресу: 119048, г. Москва, ул. Усачева, д.33,
            стр.1. В случае направления Оператору официального запроса
            в тексте запроса Пользователю Сервиса необходимо указать
            его: ФИО; сведения, подтверждающие участие Пользователя
            Сервиса в отношениях с Оператором либо сведения, иным
            способом подтверждающие факт обработки Оператором
            персональных данных Пользователя Сервиса, собственноручная
            подпись гражданина (или его законного представителя). Если
            запрос отправляется в электронном виде, то он должен быть
            оформлен в виде электронного документа и направлен на
            следующий адрес электронной почты: support@podarocheck.ru.
          </li>
          <li>
            4.5. Администрация Сервиса обязуется немедленно прекратить
            обработку персональных данных Пользователя Сервиса с
            момента получения от Пользователя Сервиса письменного
            заявления (отзыва) и в случае, если сохранение
            персональных данных более не требуется для целей обработки
            персональных данных, уничтожить их в срок и на условиях,
            установленных законодательством Российской Федерации.
          </li>
          <li>
            4.6. В случае достижения цели обработки персональных
            данных Администрация Сервиса обязуется прекратить
            обработку персональных данных и уничтожить их в срок и на
            условиях, установленных законодательством Российской
            Федерации.
          </li>
          <li>
            4.7. В случае отзыва Пользователем Сервиса согласия на
            обработку персональных данных Администрация Сервиса вправе
            продолжить обработку персональных данных без согласия
            Пользователя Сервиса при наличии оснований, указанных в п.
            2-11 ч. 1 ст. 6, ч. 2 ст. 10 и ч. 2 ст. 11 Федерального
            закона «О персональных данных».
          </li>
        </ol>
      </li>
      <li>
        5. Обязательства сторон
        <ol>
          <li>
            5.1. Пользователь Сервиса обязуется:
            <ol>
              <li>
                5.1.1. Предоставлять корректную и правдивую информацию
                о персональных данных, необходимую для пользования
                Сервисом.
              </li>
              <li>
                5.1.2. Обновлять, дополнять предоставленную информацию
                о персональных данных в случае изменения данной
                информации.{' '}
              </li>
            </ol>
          </li>
          <li>
            5.2. Администрация Сервиса обязуется:
            <ol>
              <li>
                5.2.1. Использовать полученную информацию
                исключительно для целей, указанных в статье 3
                настоящей Политики конфиденциальности.
              </li>
              <li>
                5.2.2. Обеспечить хранение конфиденциальной информации
                в тайне, не разглашать без предварительного
                письменного согласия Пользователя Сервиса, а также не
                осуществлять продажу, обмен, опубликование либо
                разглашение иными возможными способами переданных
                персональных данных Пользователя Сервиса, за
                исключением предусмотренных настоящей Политикой
                конфиденциальности.
              </li>
              <li>
                5.2.3. Осуществить блокирование персональных данных,
                относящихся к соответствующему Пользователю Сервиса, с
                момента обращения или запроса Пользователя Сервиса или
                его законного представителя либо уполномоченного
                органа по защите прав субъектов персональных данных на
                период проверки в случае выявления недостоверных
                персональных данных или неправомерных действий.
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        6. Ответственность сторон
        <ol>
          <li>
            6.1. Администрация Сервиса несёт ответственность за
            умышленное разглашение персональных данных Пользователя
            Сервиса в соответствии с действующим законодательством
            Российской Федерации, за исключением случаев,
            предусмотренных настоящей Политикой конфиденциальности.
          </li>
          <li>
            6.2. В случае утраты или разглашения персональных данных
            Администрация Сервиса не несёт ответственность, если
            данная конфиденциальная информация:
            <ol>
              <li>
                6.2.1. Стала публичным достоянием до её утраты или
                разглашения.
              </li>
              <li>
                6.2.2. Была получена от третьей стороны до момента её
                получения Администрацией Сервиса.
              </li>
              <li>
                6.2.3. Была разглашена с согласия Пользователя
                Сервиса.
              </li>
              <li>
                6.2.4. Была получена третьими лицами путем
                несанкционированного доступа к файлам Сервиса.
              </li>
            </ol>
          </li>
          <li>
            6.3. Пользователь Сервиса несет ответственность за
            правомерность, корректность и правдивость предоставленных
            персональных данных в соответствии с законодательством
            Российской Федерации.
          </li>
        </ol>
      </li>
      <li>
        7. Сведения о реализуемых требованиях к защите персональных
        данных
        <ol>
          <li>
            7.1. Администрация Сервиса принимает необходимые и
            достаточные организационные и технические меры для защиты
            персональных данных Пользователя Сервиса от неправомерного
            или случайного доступа, уничтожения, изменения,
            блокирования, копирования, распространения, а также от
            иных неправомерных действий третьих лиц.
          </li>
        </ol>
      </li>
      <li>
        8. Разрешение споров
        <ol>
          <li>
            8.1. До обращения в суд с иском по спорам, возникающим из
            отношений между Пользователем Сервиса и Администрацией
            Сервиса, обязательным является предъявление претензии
            (письменного предложения о добровольном урегулировании
            спора).
          </li>
          <li>
            8.2. Срок ответа на претензию не более 30 календарных дней
            со дня получения претензии.
          </li>
          <li>
            8.3. При недостижении соглашения спор будет передан на
            рассмотрение в суд в соответствии с законодательством
            Российской Федерации.
          </li>
          <li>
            8.4. К настоящей Политике конфиденциальности и отношениям
            между Пользователем Сервиса и Администрацией Сервиса
            применяется законодательство Российской Федерации
          </li>
        </ol>
      </li>
      <li>
        9. Дополнительные условия
        <ol>
          <li>
            9.1. Администрация Сервиса вправе в одностороннем порядке
            вносить изменения в настоящую Политику конфиденциальности.
          </li>
          <li>
            9.2. Новая Политика конфиденциальности вступает в силу с
            момента ее размещения на сайте Сервиса, если иное не
            предусмотрено новой редакцией Политики конфиденциальности.
          </li>
          <li>
            9.3. Пользователь выражает согласие на применение новой
            Политики путем продолжения использования Сервиса.
          </li>
          <li>
            9.4. Все предложения или вопросы по настоящей Политике
            конфиденциальности, а также иные обращения Пользователям
            следует направлять на адрес электронной почты:
            support@podarocheck.ru
          </li>
        </ol>
      </li>
    </ol>
  </section>
);

export default PolicyPage;
