import React, { FC } from 'react';
import closeIcon from '../../images/icons/close.svg';
import styles from './modal-header.module.css';

interface IModalHeader {
  text: string;
  onClose: (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => void;
}

const ModalHeader: FC<IModalHeader> = ({ text, onClose }) => (
  <div className={styles.header}>
    {text ? <div className="text">{text}</div> : null}
    <div
      data-testid="modal_header-click-1"
      aria-hidden
      className={styles.close}
      onClick={onClose}
      role="button"
    >
      <img src={closeIcon} alt="close icon" />
    </div>
  </div>
);

export default ModalHeader;
