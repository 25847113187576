import React from 'react';

export const emailRegExp =
  // eslint-disable-next-line
  /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const isProd =
  window.location.href.indexOf('podarocheck.ru') !== -1;

export const COUPONS_ID = isProd ? 3336 : 3331;

const url = new URL(document.location.href);

const bannerId = url.searchParams.get('banner_id');

export const COUPONS_ALL_ID =
  bannerId || bannerId !== null ? bannerId : COUPONS_ID;

export const telephoneRegExp =
  // eslint-disable-next-line
  /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

export const inputMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.target.value = e.target.value.replace(/[^\d]/g, '');
  const regexp = /^79/;
  if (regexp.test(e.target.value)) {
    const patternForFullTelephone =
      /(\+7|8|7)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    const correctNumber = e.target.value.replace(
      patternForFullTelephone,
      '+7 ($2) $3-$4-$5',
    ); // пробуем замену если есть  7 8 +7
    // Исключение для этого правила, потому что иначе валидация не работает
    // eslint-disable-next-line no-return-assign
    return (e.target.value = correctNumber);
  }
  /* if (
    e.target.value.substring(0, 1) === '9' ||
    e.target.value.substring(0, 3) === '800'
  ) {
    const patternForTelephone =
      // eslint-disable-next-line no-useless-escape
      /[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    const correctNumber = e.target.value.replace(
      patternForTelephone,
      '($1) $2-$3-$4',
    ); // пробуем замену если нет  7 8 +7
    // Исключение для этого правила, потому что иначе валидация не работает
    // eslint-disable-next-line no-return-assign
    return (e.target.value = `+7 ${correctNumber}`);
  }
  */

  return '+79';
};

export const declensionNum = (num: number, words: string[]) =>
  words[
    num % 100 > 4 && num % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][num % 10 < 5 ? num % 10 : 5]
  ];
