import React, { FC, ReactElement, useEffect, useState } from 'react';

import { Button } from '@pofd-front/storybook';
import accountRegisteredIcon from '../../images/icons/mobile-auth-icon/account-registered-icon.svg';
import sendingFailedIcon from '../../images/icons/mobile-auth-icon/sending-failed-icon.svg';
import accountСreatedIcon from '../../images/icons/mobile-auth-icon/account-created-icon.svg';

import styles from './authorization-info-modal-content.module.scss';
import { supportHref } from '../../shared/constants/constatnts';

let infoModalIcon = '';
let infoModalTitle = '';
let infoModalText = '';
let infoModalFooter: ReactElement | string = '';

const changeCase = (
  param: string,
  mobilePhone: string,
  onToPhoneInputReturn: () => void,
  onToCodeInputReturn: (val: string) => void,
  handleFlagChange: () => void,
) => {
  switch (param) {
    case 'registration is blocked':
      infoModalIcon = '';
      infoModalTitle = 'Регистрация временно заблокирована';
      infoModalText =
        'Вы превысили лимит ввода кода. Попробуйте через час или обратитесь в службу поддержки:';
      infoModalFooter = (
        <>
          <a
            data-testid="authorization_info_modal_content-link-1"
            className="link"
            href={supportHref}
          >
            support@podarocheck.ru
          </a>
          <a
            data-testid="authorization_info_modal_content-link-2"
            href="/"
            className={`${styles.btn} ${styles['btn-primary']}`}
            type="button"
          >
            К списку купонов
          </a>
        </>
      );
      break;
    case 'existing account':
      infoModalIcon = accountRegisteredIcon;
      infoModalTitle = 'Уже есть аккаунт!';
      infoModalText = `На данный телефон уже был зарегистрирован аккаунт.
                                Хотите продолжить вход по телефону?
                                ${mobilePhone}`;
      infoModalFooter = (
        <Button
          styleType="primary"
          type="button"
          onClick={() => {
            onToCodeInputReturn('login');
          }}
          disabled={false}
          product="ofd"
          testId="authorization_info_modal_content-click-1"
          label="Войти"
          size="large"
          theme="common"
        />
      );
      break;
    case 'sending failed':
      infoModalIcon = sendingFailedIcon;
      infoModalTitle = 'Сбой отправки кода';
      infoModalText = 'Попробуйте повторить попытку позднее';
      infoModalFooter = (
        <Button
          styleType="primary"
          type="button"
          onClick={onToPhoneInputReturn}
          disabled={false}
          product="ofd"
          testId="authorization_info_modal_content-click-2"
          label="Повторить"
          size="large"
          theme="common"
        />
      );
      break;
    case 'entrance is blocked':
      infoModalIcon = '';
      infoModalTitle = 'Вход временно заблокирован';
      infoModalText =
        'Вы превысили лимит ввода кода. Попробуйте через час или обратитесь в службу поддержки:';
      infoModalFooter = (
        <>
          <a
            data-testid="authorization_info_modal_content-link-3"
            className="link"
            href={supportHref}
          >
            support@podarocheck.ru
          </a>
          <a
            data-testid="authorization_info_modal_content-link-4"
            href="/"
            className={`${styles.btn} ${styles['btn-primary']}`}
            type="button"
          >
            К списку купонов
          </a>
        </>
      );
      break;
    case 'account created':
      infoModalIcon = accountСreatedIcon;
      infoModalTitle = 'Аккаунт успешно создан';
      infoModalText = '';
      infoModalFooter = '';
      break;
    case 'sending not coming':
      infoModalIcon = '';
      infoModalTitle = 'Не приходит SMS?';
      infoModalText = `Доставка сообщения может занять до 5 минут и зависит от сотовой связи.
                                Проверьте состояние сети и правильность указанного телефона:
                                ${mobilePhone}`;
      infoModalFooter = (
        <>
          <Button
            styleType="primary"
            type="button"
            onClick={onToPhoneInputReturn}
            disabled={false}
            product="ofd"
            testId="authorization_info_modal_content-click-3"
            label="Редактировать"
            size="large"
            theme="common"
          />
          <div className={styles['mt-3']}>
            <Button
              styleType="invisible"
              type="button"
              onClick={() => {
                onToCodeInputReturn('');
              }}
              disabled={false}
              product="ofd"
              testId="authorization_info_modal_content-click-4"
              label="Всё верно"
              size="large"
              theme="common"
            />
          </div>
        </>
      );
      break;
    case 'account not registered':
      infoModalIcon = '';
      infoModalTitle = 'Аккаунт не зарегистрирован';
      infoModalText = `На данный телефон еще не был зарегистрирован аккаунт.
                                Хотите продолжить регистрацию по телефону:
                                ${mobilePhone}`;
      infoModalFooter = (
        <>
          <Button
            styleType="primary"
            type="button"
            onClick={() => {
              onToCodeInputReturn('registration');
            }}
            disabled={false}
            product="ofd"
            testId="authorization_info_modal_content-click-5"
            label="Зарегистрироваться"
            size="large"
            theme="common"
          />
          <div className={styles['mt-3']}>
            <Button
              styleType="secondary"
              type="button"
              onClick={onToPhoneInputReturn}
              disabled={false}
              product="ofd"
              testId="authorization_info_modal_content-click-6"
              label="У меня есть аккаунт"
              size="large"
              theme="common"
            />
          </div>
        </>
      );
      break;
    default:
      infoModalIcon = '';
      infoModalTitle = '';
      infoModalText = '';
      infoModalFooter = '';
      break;
  }
  handleFlagChange();
};

interface IAuthorizationInfoModalContent {
  mainMobileAuthCase: string;
  mobilePhone: string;
  onToPhoneInputReturn: () => void;
  onToCodeInputReturn: (val: string) => void;
}

const AuthorizationInfoModalContent: FC<
  IAuthorizationInfoModalContent
> = ({
  mainMobileAuthCase,
  mobilePhone,
  onToPhoneInputReturn,
  onToCodeInputReturn,
}) => {
  const [changeCaseFlag, setChangeCaseFlag] = useState(false);

  const handleFlagChange = () => {
    setChangeCaseFlag(true);
  };

  useEffect(() => {
    changeCase(
      mainMobileAuthCase,
      mobilePhone,
      onToPhoneInputReturn,
      onToCodeInputReturn,
      handleFlagChange,
    );
  }, [mainMobileAuthCase]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {changeCaseFlag ? (
        <>
          {infoModalIcon !== '' ? (
            <div className={styles['mb-3']}>
              <img src={infoModalIcon} alt={infoModalIcon} />
            </div>
          ) : null}
          <div className="text-center">
            <h3 className={`${styles.title}`}>{infoModalTitle}</h3>
            <div>
              <p className={`${styles.text}`}>{infoModalText}</p>
            </div>
            {infoModalFooter !== '' ? infoModalFooter : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AuthorizationInfoModalContent;
