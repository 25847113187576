import classNames from 'classnames/bind';
import React, { FC, useEffect, useState } from 'react';
import { Button } from '@pofd-front/storybook';

import Modal from '../modal/modal';

import styles from './coupon-modal.module.scss';
import { DistributedDataResponse } from '../../shared/dto/coupons-dto';

const cx = classNames.bind(styles);

const copyToClipboard = (value: string) => {
  if (
    navigator &&
    navigator.clipboard &&
    navigator.clipboard.writeText
  ) {
    return navigator.clipboard.writeText(value);
  }
  return Promise.reject(
    new Error('The Clipboard API is not available.'),
  );
};

interface ICouponModal {
  onClose: () => void;
  promo: null | DistributedDataResponse;
  onOpenSiteButtonClick: (place: string) => void;
}

const CouponModal: FC<ICouponModal> = ({
  promo,
  onOpenSiteButtonClick,
  onClose,
}) => {
  const [promocode, setPromocode] = useState('');

  useEffect(() => {
    setPromocode(promo?.promoCode.code!);
  }, [promo]);

  useEffect(() => {
    if (promocode.trim() !== '') copyToClipboard(promocode);
  }, [promocode]);

  return (
    <Modal
      headerText="Ваш промокод"
      fullWidth={false}
      onClose={onClose}
    >
      <div>
        <div className={cx('modal-inner')}>
          <div className={cx('text-wrapper')}>
            <p className={cx('text')}>
              {promo?.coupon.applyInstruction !== null
                ? promo?.coupon.applyInstruction
                : 'Промокод скопирован. Перейдите на сайт и примените его\n' +
                  '              при оформлении заказа.'}
            </p>
          </div>
          <div className={cx('promocode-wrapper')}>
            <input
              data-testid="coupon_modal-input-1"
              type="text"
              size={20}
              className={cx('promocode')}
              defaultValue={promocode}
              readOnly
            />
            {promocode ? (
              <div className={cx('clipboard-success')} />
            ) : null}
          </div>
          <Button
            type="button"
            disabled={false}
            onClick={() => onOpenSiteButtonClick('popup')}
            testId="coupon_modal-btn-1"
            styleType="primary"
            size="large"
            // @ts-ignore
            label={
              promo?.coupon.buttonText !== null
                ? promo?.coupon.buttonText
                : 'Перейти на сайт'
            }
            product="ofd"
          />
        </div>
        <div className={cx('gradient')} />
      </div>
    </Modal>
  );
};

export default CouponModal;
