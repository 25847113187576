import React, { FC, memo } from 'react';

import styles from './spinner.module.scss';

interface ILoaderSpinnerProps {
  customClassName?: string;
}

const LoaderSpinner: FC<ILoaderSpinnerProps> = memo(
  ({ customClassName }) => (
    <svg
      className={[styles.loader, customClassName].join(' ')}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="gradient">
          <stop offset="8%" stopColor="#3c9f83" />
          <stop offset="70%" stopColor="#4ba1a5" />
        </linearGradient>
      </defs>
      <circle
        cx="50"
        cy="50"
        r="30"
        fill="none"
        stroke="url(#gradient)"
      />
    </svg>
  ),
);

LoaderSpinner.displayName = 'LoaderSpinner';

export default LoaderSpinner;
