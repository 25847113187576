import React from 'react';

import { Container } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import styles from './feedback.module.scss';
import logoImg from '../images/icons/logo-pcheck.svg';

const FeedbackPage = () => (
  <div className={styles.wrap}>
    <Container className={styles.container}>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="logo">
          <Link to="/" data-testid="feedback_page-nav-link-1">
            <img
              className={styles['m-auto-md-0']}
              src={logoImg}
              alt="feedback logo"
            />
          </Link>
        </div>
        <div
          className={`${styles.block} ${styles['mt-4']} d-flex flex-column justify-content-center align-items-center text-center`}
        >
          <h1 className={`${styles.title} ${styles['mt-3']}`}>
            Свяжитесь с нами
          </h1>
          <p>
            Нашли недостоверную информацию?
            <br /> Возникла проблема в работе приложения? <br />
            Хотите поделиться опытом использования приложения,
            предложить идею?
            <br /> Напишите нам, и мы обязательно ответим.
          </p>
          <div className={`${styles['mt-3']} ${styles['mb-3']}`}>
            <a
              data-testid="feedback_page-link-1"
              href=" https://forms.yandex.ru/u/63289a0689d3816378d41c33/"
              className={styles.btn}
              type="button"
            >
              Написать в поддержку
            </a>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default FeedbackPage;
