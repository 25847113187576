import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICoupons } from '../interfaces';
import TFilteredCouponsListTypes from './models/filteredCouponsList.model';

const initialState: TFilteredCouponsListTypes = {
  filteredCoupons: [],
  totalPage: 0,
  page: 0,
};

const filteredCouponsListSlice = createSlice({
  name: 'coupons and categories',
  initialState,

  reducers: {
    setFilteredCouponsList(state, action: PayloadAction<ICoupons[]>) {
      state.filteredCoupons = action.payload;
    },

    addFilteredCouponsList(state, action: PayloadAction<ICoupons[]>) {
      state.filteredCoupons = state.filteredCoupons.concat(
        action.payload,
      );
    },

    setTotalPage(state, action: PayloadAction<number>) {
      state.totalPage = action.payload;
    },

    setNextPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
  },
});

export const {
  setFilteredCouponsList,
  addFilteredCouponsList,
  setTotalPage,
  setNextPage,
} = filteredCouponsListSlice.actions;

export default filteredCouponsListSlice.reducer;
