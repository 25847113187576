import React, { FC } from 'react';

import { Link } from 'react-router-dom';

import styles from './search-result.module.scss';
import { ICoupons } from '../../interfaces';

interface ISearchResult {
  elem: ICoupons;
  func: () => void;
}

const SearchResult: FC<ISearchResult> = ({ elem, func }) => (
  <div>
    <Link
      data-testid="search_result-nav-link-1"
      onClick={func}
      to={`/gift/${elem.couponId}`}
      className={`${styles.link} d-flex align-items-center`}
    >
      <div className={styles.logo}>
        <img
          src={elem.altLogo ? elem.altLogo : elem.shopLogoLink}
          alt="coupon logo"
        />
      </div>
      <div className={`${styles.main}`}>
        <div className="d-md-flex justify-content-between">
          <div className={styles.title}>{elem.title}</div>
          <div className={styles['shop-name']}>{elem.shopName}</div>
        </div>
        <div className={styles.desc}>
          {elem.shortDescription} <span />
        </div>
      </div>
    </Link>
  </div>
);

export default SearchResult;
