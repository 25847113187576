import { createSlice } from '@reduxjs/toolkit';
import IMobileSearch from './models/mobileSearch.module';

const initialState: IMobileSearch = {
  openFlag: false,
};

const mobileSearchSlice = createSlice({
  name: 'mobileSearch',
  initialState,

  reducers: {
    setMobileSearchFlag(state, action) {
      return {
        openFlag: action.payload,
      };
    },
  },
});

export const { setMobileSearchFlag } = mobileSearchSlice.actions;

export default mobileSearchSlice.reducer;
